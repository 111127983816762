import '../styles/home.css';
import issue from '../images/March 2024 cover.webp';
import Swiper from './Swiper';
import { wmcApi } from '../utils/api';
import FileDownload from 'js-file-download';
// import TwitterFeed from './TwitterFeed';
import { Link } from 'react-router-dom';
import firstissue from '../images/autumn-2024.webp';
import masterseries_register from '../images/vascular-poster.png';
import vascular_slide from '../images/vascular-slide.webp';
import ewma1small from '../images/space-slide.webp';
import podcast3small from '../images/podcast-poster-episode3 small.webp';
import summit3poster from '../images/skin-substitutes-poster.webp'
import wound_bed_prep_poster from '../images/MasterSeries May 8 V7.webp'
import stoma1 from '../images/stoma 1.webp'
import stoma2 from '../images/stoma 2.webp'
import stoma3 from '../images/stoma 3.webp'

import vascular_cover from '../images/vascular-poster-cover.webp';
import { Carousel } from 'react-responsive-carousel';

import { useState, useEffect } from 'react';

const Home = ({ accessToken, setNotOptions, auth, setPodcastLogo }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);
  useEffect(() => {
    setPodcastLogo(true);
  }, []);

  const handleDownload = () => {
    wmcApi
      .get('/download', {
        url: 'https://wmcapi.herokuapp.com/api/download',
        responseType: 'blob',
        headers: {
          authorization: accessToken,
        },
      })
      .then((response) => {
        FileDownload(response.data, 'testimg.png');
      });
  };

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  if (!auth) {
    return (
      <section className='page-container'>
        <div className='home-ewma-ad-container'>
          <Link to='/Register'>
            <img
              src={firstissue}
              className='offer-home'
              alt='register offer'
            ></img>
          </Link>

          <div className='offer-home'>
            
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                interval={6000}
                stopOnHover={false}
              >
                <a href='https://woundmasterclass.com/bioactive-matrix' target='_blank'> 
                <div>
                
                  <img src={wound_bed_prep_poster} />
                  
                </div>
                </a>
                
                
                <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
                <div>
                <img src={stoma1} />
              </div>
              </a>

              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma2} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma3} />
              </div>
              </a>
              </Carousel>
            
          </div>
        </div>
        <section className='home-container'>
          <div className='current-issue-container'>
            <h1 className='content-titles'>Spring 2024 ISSUE</h1>

            <div className='current-issue-box'>
              <img
                src={issue}
                className='issue-img'
                alt='Wound Masterclass issue'
                // onClick={() => {
                //   handleDownload();
                // }}
              ></img>

              <span id='about-issue'>
                <br />
                <p>
                  Our Spring 2024 issue is now available for registered
                  readers.
                  <br />
                  <br />
                  Read more about the journal{' '}
                  <Link to='/About'>
                    <span
                      className='email-links'
                      onClick={() => {
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      here.
                    </span>
                  </Link>
                  <br />
                  <br />
                  See our international{' '}
                  <Link to='/About'>
                    <span
                      className='email-links'
                      onClick={() => {
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      editorial board.
                    </span>
                  </Link>
                  <br />
                  <br />
                  Please{' '}
                  <Link to='/Register'>
                    <span className='email-links' onClick={() => scrollToTop()}>
                      register{' '}
                    </span>
                  </Link>
                  with us to get free, full access to the journal.
                  <br />
                  <br />
                </p>
              </span>
            </div>
          </div>
          <div className='featured-content-container'>
            <h2 className='content-titles'>CONTENT THIS ISSUE</h2>
            <div className='featured-content-box'>
              <Swiper />
            </div>
          </div>
        </section>
        {/* <div className='offer-twitter'>
        <div className='twitter-container'>
          <div className='twitter-box'>
            <TwitterFeed />
          </div>
        </div>
      </div> */}
      </section>
    );
  } else {
    return (
      <section className='page-container'>
        <div className='home-ewma-ad-container'>
          <Link to='/Register'>
            <img
              src={firstissue}
              className='offer-home'
              alt='register offer'
            ></img>
          </Link>

          <div className='offer-home'>
            
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                interval={6000}
                stopOnHover={false}
              >
                 <a href='https://woundmasterclass.com/bioactive-matrix' target='_blank'> 
                <div>
                
                  <img src={wound_bed_prep_poster} />
                </div>
                </a>
                <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
                <div>
                <img src={stoma1} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma2} />
              </div>
              </a>
              <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
              <div>
                <img src={stoma3} />
              </div>
              </a>
              </Carousel>
           
          </div>
        </div>
        <section className='home-container'>
          <div className='current-issue-container'>
            <h1 className='content-titles'>Spring 2024 ISSUE</h1>

            <div className='current-issue-box'>
            <div
  style={{
    position: "relative",
    paddingTop: "max(60%,326px)",
    height: 0,
    width: "100%"
  }}
>
  <iframe
    allow="clipboard-write"
    sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
    allowFullScreen="true"
    style={{
      position: "absolute",
      border: "none",
      width: "100%",
      height: "100%",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }}
    src="https://e.issuu.com/embed.html?d=wound_masterclass_-_march_2024&hideIssuuLogo=true&u=woundmasterclass"
  />
</div>

            </div>
          </div>
          <div className='featured-content-container'>
            <h2 className='content-titles'>CONTENT THIS ISSUE</h2>
            <div className='featured-content-box'>
              <Swiper />
            </div>
          </div>
        </section>
        {/* <div className='offer-twitter'>
            <div className='twitter-container'>
              <div className='twitter-box'>
                <TwitterFeed />
              </div>
            </div>
          </div> */}
      </section>
    );
  }
};

export default Home;
