import '../styles/mobile-header.css';
import twitter from '../images/x-icon.webp';
import facebook from '../images/facebook-icon.png';
import linkedin from '../images/linkedin-icon.png';
import instagram from '../images/instagram-icon.png';
import Cookies from 'js-cookie';
import wmc_header from '../images/wmc-header.webp';
import tagline from '../images/tagline-mobile.gif';

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const MobileHeader = ({
  auth,
  setAuth,
  loginPage,
  registerPage,
  subscribePage,
  notOptions,
  userFirstName,
  secureLogin,
  setSecureLogin,
  secureSession,
  setSecureSession,
  podcastLogo,
}) => {
  const loginOptions =
    loginPage && !notOptions
      ? 'mobile-options-page-active'
      : 'mobile-options-page';
  const registerOptions =
    registerPage && !notOptions
      ? 'mobile-options-page-active'
      : 'mobile-options-page';
  const subscribeOptions =
    subscribePage && !notOptions
      ? 'mobile-options-page-active'
      : 'mobile-options-page';
  const handleLogout = () => {
    Cookies.remove('user');
    setTimeout(() => setAuth(false), 100);
    window.location.reload(false);
  };



  if (auth) {
    return (
      <>
        <Link to='/Home'>
          <img
            src={wmc_header}
            className='header-img-mobile'
            alt='Wound Masterclass'
          ></img>
        </Link>
        <div className='tagline-mobile'>
          <img src={tagline} className='tagline-mobile-gif'></img>
        </div>
        <hr></hr>
        <section className='mobile-options-bar'>
          <div>
            <span
              className='logout-span'
              onClick={() => {
                handleLogout();
              }}
            >
              Log out{` ${userFirstName}`}
            </span>
          </div>
          <div>
            <Link to='/Accounts'>
              <span
                className='logout-span'
                onClick={() => {
                  if (!secureSession) {
                    setSecureLogin(true);
                  }
                }}
              >
                My Account
              </span>
            </Link>
          </div>
          <div>
            <Link to='/Subscribe'>
              <span className={subscribeOptions} id='subscribe-option-mobile'>
                Submissions
              </span>
            </Link>
          </div>
        </section>

        <section className='mobile-nav-container'>
          <div className='mobile-nav-buttons'>
          <Link to='/About' className='mobile-nav-button'>
            <span>ABOUT</span>
          </Link>
          <Link to='/Issues' className='mobile-nav-button'>
            <span>ISSUES</span>
          </Link>
          <Link to='/Articles' className='mobile-nav-button'>
            <span>ARTICLES</span>
          </Link>
          <Link to='/Events' className='mobile-nav-button'>
            <span>EVENTS</span>
          </Link>
          <Link to='/Contact' className='mobile-nav-button'>
            <span>CONTACT</span>
          </Link>
          </div>
          <div className='podcast-video-buttons'><Link to='/Video' className='mobile-nav-button'>
            <span>VIDEO</span>
          </Link>
          <Link to='/Podcast' className='mobile-nav-button'>
            <span>PODCAST</span>
          </Link></div>
        </section>
        <div className='smi-div-container'>
          
          <div className='smi-div'>
            <a href='https://www.twitter.com/wound_mc' target='_blank'>
              <img
                src={twitter}
                className='mobile-social-media-icons'
                alt='Twitter'
                id='twitter-icon'
              ></img>
            </a>
            <a
              href='https://www.facebook.com/people/Wound-Masterclass/100079115137288/'
              target='_blank'
            >
              <img
                src={facebook}
                className='mobile-social-media-icons'
                alt='Facebook'
              ></img>
            </a>
            <a
              href='https://www.linkedin.com/company/wound-masterclass/'
              target='_blank'
            >
              <img
                src={linkedin}
                className='mobile-social-media-icons'
                alt='Linkedin'
              ></img>
            </a>
            <a
              href='https://www.instagram.com/wound_masterclass'
              target='_blank'
            >
              <img
                src={instagram}
                className='mobile-social-media-icons'
                alt='Instagram'
                id='mobile-instagram-icon'
              ></img>
            </a>
            
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Link to='/Home'>
          <img
            src={wmc_header}
            className='header-img-mobile'
            alt='Wound Masterclass'
          ></img>
        </Link>
        <div className='tagline-mobile'>
          <img src={tagline} className='tagline-mobile-gif'></img>
        </div>
        <hr></hr>
        <section className='mobile-options-bar'>
          <div>
            <Link to='/Login'>
              <span className={loginOptions}>LOG IN</span>
            </Link>

            <Link to='/Register'>
              <span className={registerOptions}>REGISTER</span>
            </Link>
          </div>

          <div>
            <Link to='/Subscribe'>
              <span className={subscribeOptions} id='subscribe-option-mobile'>
                SUBMISSIONS
              </span>
            </Link>
          </div>
        </section>
        <hr></hr>

        <section className='mobile-nav-container'>
          <div className='mobile-nav-buttons'>
          <Link to='/About' className='mobile-nav-button'>
            <span>ABOUT</span>
          </Link>
          <Link to='/Issues' className='mobile-nav-button'>
            <span>ISSUES</span>
          </Link>
          <Link to='/Articles' className='mobile-nav-button'>
            <span>ARTICLES</span>
          </Link>
          <Link to='/Events' className='mobile-nav-button'>
            <span>EVENTS</span>
          </Link>
          <Link to='/Contact' className='mobile-nav-button'>
            <span>CONTACT</span>
          </Link>
          </div>
          <div className='podcast-video-buttons'><Link to='/Video' className='mobile-nav-button'>
            <span>VIDEO</span>
          </Link>
          <Link to='/Podcast' className='mobile-nav-button'>
            <span>PODCAST</span>
          </Link></div>
        </section>
        <div className='smi-div-container'>
      
          <div className='smi-div'>
            <a href='https://www.twitter.com/wound_mc' target='_blank'>
              <img
                src={twitter}
                className='mobile-social-media-icons'
                alt='Twitter'
                id='twitter-icon'
              ></img>
            </a>
            <a
              href='https://www.facebook.com/people/Wound-Masterclass/100079115137288/'
              target='_blank'
            >
              <img
                src={facebook}
                className='mobile-social-media-icons'
                alt='Facebook'
              ></img>
            </a>
            <a
              href='https://www.linkedin.com/company/wound-masterclass/'
              target='_blank'
            >
              <img
                src={linkedin}
                className='mobile-social-media-icons'
                alt='Linkedin'
              ></img>
            </a>
            <a
              href='https://www.instagram.com/wound_masterclass'
              target='_blank'
            >
              <img
                src={instagram}
                className='mobile-social-media-icons'
                alt='Instagram'
                id='instagram-icon'
              ></img>
            </a>
            
          </div>
        </div>
      </>
    );
  }
};

export default MobileHeader;
