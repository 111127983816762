import '../styles/cookies-policy.css';
import { useEffect } from 'react';

const CookiesPolicy = ({ setNotOptions, setPodcastLogo }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);
  useEffect(() => {
    setPodcastLogo(true);
  }, []);
  return (
    <>
      <h1 className='cookies-policy-title'>COOKIES POLICY</h1>
      <div className='page-wrapper'>
        <section className='cookies-policy-container'>
          <br />
          <br />
          <br />
          <h1
            style={{
              margin: '0px 0px 16px',
              fontSize: '40px',
              lineHeight: '60px',
              fontWeight: 700,
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Cookies Policy
          </h1>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Last updated: January 18, 2022
          </p>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            This Cookies Policy explains what Cookies are and how We use them.
            You should read this policy so You can understand what type of
            cookies We use, or the information We collect using Cookies and how
            that information is used.
          </p>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Cookies do not typically contain any information that personally
            identifies a user, but personal information that we store about You
            may be linked to the information stored in and obtained from
            Cookies. For further information on how We use, store and keep your
            personal data secure, see our Privacy Policy.
          </p>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            We do not store sensitive personal information, such as mailing
            addresses, account passwords, etc. in the Cookies We use.
          </p>
          <h1
            style={{
              margin: '0px 0px 16px',
              fontSize: '40px',
              lineHeight: '60px',
              fontWeight: 700,
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Interpretation and Definitions
          </h1>
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Interpretation
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Definitions
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            For the purposes of this Cookies Policy:
          </p>
          <ul
            style={{
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            <li style={{ margin: '0px 0px 16px' }}>
              <strong>Company</strong> (referred to as either "the Company",
              "We", "Us" or "Our" in this Cookies Policy) refers to Wound
              Masterclass.
            </li>
            <li style={{ margin: '0px 0px 16px' }}>
              <strong>Cookies</strong> means small files that are placed on Your
              computer, mobile device or any other device by a website,
              containing details of your browsing history on that website among
              its many uses.
            </li>
            <li style={{ margin: '0px 0px 16px' }}>
              <strong>Website</strong> refers to Wound Masterclass, accessible
              from&nbsp;
              <a
                href='https://www.woundmasterclass.com'
                rel='external nofollow noopener'
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: 'rgb(0, 132, 97)',
                  wordWrap: 'break-word',
                }}
                target='_blank'
              >
                woundmasterclass.com
              </a>
            </li>
            <li style={{ margin: '0px 0px 16px' }}>
              <strong>You</strong> means the individual accessing or using the
              Website, or a company, or any legal entity on behalf of which such
              individual is accessing or using the Website, as applicable.
            </li>
          </ul>
          <h1
            style={{
              margin: '0px 0px 16px',
              fontSize: '40px',
              lineHeight: '60px',
              fontWeight: 700,
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            The use of the Cookies
          </h1>
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Type of Cookies We Use
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close your
            web browser.
          </p>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            We use both session and persistent Cookies for the purposes set out
            below:
          </p>
          <ul
            style={{
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            <li style={{ margin: '0px 0px 16px' }}>
              <p style={{ margin: '0px 0px 16px' }}>
                <strong>Necessary / Essential Cookies</strong>
              </p>
              <p style={{ margin: '0px 0px 16px' }}>Type: Session Cookies</p>
              <p style={{ margin: '0px 0px 16px' }}>Administered by: Us</p>
              <p style={{ margin: '0px 0px 16px' }}>
                Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.
              </p>
            </li>
            <li style={{ margin: '0px 0px 16px' }}>
              <p style={{ margin: '0px 0px 16px' }}>
                <strong>Functionality Cookies</strong>
              </p>
              <p style={{ margin: '0px 0px 16px' }}>Type: Persistent Cookies</p>
              <p style={{ margin: '0px 0px 16px' }}>Administered by: Us</p>
              <p style={{ margin: '0px 0px 16px' }}>
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website.
              </p>
            </li>
          </ul>
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Your Choices Regarding Cookies
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            If You prefer to avoid the use of Cookies on the Website, first You
            must disable the use of Cookies in your browser and then delete the
            Cookies saved in your browser associated with this website. You may
            use this option for preventing the use of Cookies at any time.
          </p>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            If You do not accept Our Cookies, You may experience some
            inconvenience in your use of the Website and some features may not
            function properly.
          </p>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            If You'd like to delete Cookies or instruct your web browser to
            delete or refuse Cookies, please visit the help pages of your web
            browser.
          </p>
          <ul
            style={{
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            <li style={{ margin: '0px 0px 16px' }}>
              <p style={{ margin: '0px 0px 16px' }}>
                For the Chrome web browser, please visit this page from
                Google:&nbsp;
                <a
                  href='https://support.google.com/accounts/answer/32050'
                  rel='external nofollow noopener'
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'rgb(0, 132, 97)',
                    wordWrap: 'break-word',
                  }}
                  target='_blank'
                >
                  https://support.google.com/accounts/answer/32050
                </a>
              </p>
            </li>
            <li style={{ margin: '0px 0px 16px' }}>
              <p style={{ margin: '0px 0px 16px' }}>
                For the Internet Explorer web browser, please visit this page
                from Microsoft:&nbsp;
                <a
                  href='http://support.microsoft.com/kb/278835'
                  rel='external nofollow noopener'
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'rgb(0, 132, 97)',
                    wordWrap: 'break-word',
                  }}
                  target='_blank'
                >
                  http://support.microsoft.com/kb/278835
                </a>
              </p>
            </li>
            <li style={{ margin: '0px 0px 16px' }}>
              <p style={{ margin: '0px 0px 16px' }}>
                For the Firefox web browser, please visit this page from
                Mozilla:&nbsp;
                <a
                  href='https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored'
                  rel='external nofollow noopener'
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'rgb(0, 132, 97)',
                    wordWrap: 'break-word',
                  }}
                  target='_blank'
                >
                  https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
                </a>
              </p>
            </li>
            <li style={{ margin: '0px 0px 16px' }}>
              <p style={{ margin: '0px 0px 16px' }}>
                For the Safari web browser, please visit this page from
                Apple:&nbsp;
                <a
                  href='https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac'
                  rel='external nofollow noopener'
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'rgb(0, 132, 97)',
                    wordWrap: 'break-word',
                  }}
                  target='_blank'
                >
                  https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                </a>
              </p>
            </li>
          </ul>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            For any other web browser, please visit your web browser's official
            web pages.
          </p>
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            More Information about Cookies
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            You can learn more about cookies:&nbsp;
            <a
              href='https://www.allaboutcookies.org/'
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'rgb(0, 132, 97)',
                wordWrap: 'break-word',
              }}
              target='_blank'
            >
              https://www.allaboutcookies.org/
            </a>
          </p>
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Contact Us
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            If you have any questions about this Cookies Policy, You can contact
            us:
          </p>
          <ul
            style={{
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            <li style={{ margin: '0px 0px 16px' }}>
              <p style={{ margin: '0px 0px 16px', wordWrap: 'break-word' }}>
                By email:
                <br />
                <br />
                &nbsp;admin@woundmasterclass.com
              </p>
            </li>
            <li style={{ margin: '0px 0px 16px' }}>
              <p style={{ margin: '0px 0px 16px' }}>
                By visiting this page on our website:
                <br />
                <br />
                &nbsp;
                <a
                  href='https://www.woundmasterclass.com/Contact'
                  rel='external nofollow noopener'
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'rgb(0, 132, 97)',
                    wordWrap: 'break-word',
                  }}
                  target='_blank'
                >
                  woundmasterclass.com/Contact
                </a>
              </p>
            </li>
          </ul>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </section>
      </div>
    </>
  );
};

export default CookiesPolicy;
