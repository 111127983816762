import '../styles/nav.css';

import twitter_icon from '../images/x-icon.webp';
import facebook_icon from '../images/facebook-icon.png';
import linkedin_icon from '../images/linkedin-icon.png';
import instagram_icon from '../images/instagram-icon.png';
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <>
      <div className='nav-bar'>
        <section className='nav-container'>
          <Link to='/About' className='nav-button'>
            <span>ABOUT</span>
          </Link>
          <Link to='/Issues' className='nav-button'>
            <span>ISSUES</span>
          </Link>
          <Link to='/Articles' className='nav-button'>
            <span>ARTICLES</span>
          </Link>
          <Link to='/Events' className='nav-button'>
            <span>EVENTS</span>
          </Link>
          <Link to='/Contact' className='nav-button'>
            <span>CONTACT</span>
          </Link>
          <Link to='/Video' className='nav-button'>
            <span>VIDEO</span>
          </Link>
          <Link to='/Podcast' className='nav-button'>
            <span>PODCAST</span>
          </Link>
          
        </section>
        <div className='social-media-home'>
          <a href='https://www.twitter.com/wound_mc' target='_blank'>
            <img
              src={twitter_icon}
              className='social-media-icons'
              alt='Twitter'
              id='twitter-icon-desktop-nav'
            ></img>
          </a>{' '}
          <a
            href='https://www.facebook.com/people/Wound-Masterclass/100079115137288/'
            target='_blank'
          >
            {' '}
            <img
              src={facebook_icon}
              className='social-media-icons'
              alt='Facebook'
            ></img>
          </a>
          <a
            href='https://www.linkedin.com/company/wound-masterclass/'
            target='_blank'
          >
            <img
              src={linkedin_icon}
              className='social-media-icons'
              alt='Linkedn'
            ></img>
          </a>
          <a href='https://www.instagram.com/wound_masterclass' target='_blank'>
            <img
              src={instagram_icon}
              id='instagram-icon'
              className='social-media-icons'
              alt='Instagram'
            ></img>
          </a>
        </div>
      </div>
    </>
  );
};

export default Nav;
