import '../styles/header.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Nav from '../components/Nav';
import Cookies from 'js-cookie';
import Header_Image from '../images/wmc-header.webp';
import tagline from '../images/tagline-desktop.gif';

const Header = ({
  auth,
  setAuth,
  loginPage,
  registerPage,
  subscribePage,
  notOptions,
  userFirstName,
  secureLogin,
  setSecureLogin,
  secureSession,
  setSecureSession,
}) => {
  const loginOptions =
    loginPage && !notOptions ? 'options-page-active' : 'options-page';
  const registerOptions =
    registerPage && !notOptions ? 'options-page-active' : 'options-page';
  const subscribeOptions =
    subscribePage && !notOptions ? 'options-page-active' : 'options-page';
  const handleLogout = () => {
    Cookies.remove('user');
    setTimeout(() => setAuth(false), 100);
    window.location.reload(false);
  };
  if (!auth) {
    return (
      <>
        <div>
          <Link to='/Home'>
            <img src={Header_Image} className='header-img'></img>
          </Link>
        </div>
        <div className='desktop-tagline-gif'>
          <img src={tagline}></img>
        </div>
        <div className='options-bar'>
          <div>
            <Link to='/Login' className='options-links'>
              <span className={loginOptions}>LOG IN</span>
            </Link>
            <Link to='Register' className='options-links'>
              <span className={registerOptions}>REGISTER</span>
            </Link>
          </div>

          <Link to='Subscribe' className='options-links'>
            <span className={subscribeOptions}>SUBMISSIONS</span>
          </Link>
        </div>
        <section className='nav-section'>
          <Nav />
        </section>
      </>
    );
  } else if (auth) {
    return (
      <>
        <div>
          <Link to='/Home'>
            <img src={Header_Image} className='header-img'></img>
          </Link>
        </div>
        <div className='desktop-tagline-gif'>
          <img src={tagline}></img>
        </div>
        <div className='options-bar'>
          <div>
            <span
              className={loginOptions}
              id='logout-span'
              onClick={() => handleLogout()}
            >
              Log out{` ${userFirstName}`}
            </span>
          </div>
          <div>
            <Link to='/Accounts'>
              <span
                className='logout-span'
                onClick={() => {
                  setSecureLogin(true);
                }}
              >
                My Account
              </span>
            </Link>
          </div>
          <Link to='Subscribe' className='options-links'>
            <span className={subscribeOptions}>Subscribe</span>
          </Link>
        </div>
        <section className='nav-section'>
          <Nav />
        </section>
      </>
    );
  }
};

export default Header;
