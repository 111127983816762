import '../styles/video.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import vascular_slide from '../images/vascular-slide.webp';
import ssi_slide from '../images/ssi-slide.webp';
import oxygen_slide from '../images/oxygen-slide.webp';
import pressure_slide from '../images/pressure-slide.webp';
import palliative_slide from '../images/palliative-poster-speakers.webp'
import space_slide from '../images/space-slide.webp'
import jci from '../images/jci.png';
import ReactPlayer from 'react-player';
import dates_slide from '../images/dates slide.webp'
import summit3poster from '../images/summit 3 poster.webp'
import skinsubsposter from '../images/skin-substitutes-poster-big.webp'
import woundbedprepposter from '../images/wound-bed-prep-poster.webp'
import bioactiveglassposter from '../images/ms poster 05-03-2024.webp'
import msgeneric from '../images/ms generic.webp'
import dfuposter from '../images/MasterSeries May 8 V7.webp'
import convexitythumbnail from '../images/convexity-thumbnail.webp'
import globalnewthumbnail from '../images/global-new-thumbnail.webp'

const Video = ({ setNotOptions, mobile, setPodcastLogo }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);
  useEffect(() => {
    setPodcastLogo(true);
  }, []);
  const [videoOne, setVideoOne] = useState(false);
  const [videoTwo, setVideoTwo] = useState(false);
  const [videoThree, setVideoThree] = useState(false);
  const [videoFour, setVideoFour] = useState(false);
  const [videoFive, setVideoFive] = useState(false);
  const [videoSix, setVideoSix] = useState(false);
  const [videoSeven, setVideoSeven] = useState(false);
  const [videoEight, setVideoEight] = useState(false);
  const [videoNine, setVideoNine] = useState(false);
  const [videoTen, setVideoTen] = useState(false);
  const [videoEleven, setVideoEleven] = useState(false);
  const [videoTwelve, setVideoTwelve] = useState(false);
  const [videoThirteen, setVideoThirteen] = useState(false);
  const [videoFourteen, setVideoFourteen] = useState(false);
  const [videoFifteen, setVideoFifteen] = useState(false);
  const [videoSixteen, setVideoSixteen] = useState(false);
  const [videoSeventeen, setVideoSeventeen] = useState(false);
  const [videoEighteen, setVideoEighteen] = useState(false);
  const [videoNineteen, setVideoNineteen] = useState(false);

  const titleStyle = mobile ? 'mobile-title' : 'events-title';
  const videoMenu = mobile ? 'mobile-video-menu' : 'desktop-video-menu';

  if (!videoOne && !videoTwo && !videoThree && !videoFour && !videoFive && !videoSix &&!videoSeven && !videoEight && !videoNine && !videoTen && !videoEleven && !videoTwelve && !videoThirteen && !videoFourteen && !videoFifteen && !videoSixteen && !videoSeventeen && !videoEighteen && !videoNineteen) {
    return (
      <section className={videoMenu}>
        
        <p className='video-main-title'>
          Welcome to Wound Masterclass video! Please find our video menu below.
        </p>
        <p className='video-one-title'>
         
          Global Innovation in Wound Care Summit Series<br />
          <br />
          Stoma Masterclass: Getting the Best Patient Outcomes: European Panel
        </p>
        <img
          src={globalnewthumbnail}
          className='vascular-slide'
          onClick={() => {
            setVideoNineteen(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
         
          Global Innovation in Wound Care Summit Series<br />
          <br />
          Stoma Masterclass: Getting the Best Patient Outcomes: North American Panel
        </p>
        <img
          src={globalnewthumbnail}
          className='vascular-slide'
          onClick={() => {
            setVideoEighteen(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
         
          Global Innovation in Wound Care Summit Series<br />
          <br />
          Stoma Masterclass: Getting the Best Patient Outcomes: APAC Panel
        </p>
        <img
          src={globalnewthumbnail}
          className='vascular-slide'
          onClick={() => {
            setVideoSeventeen(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          Translating Convexity Characteristics into Successful Clinical Outcomes: New Evidence to Guide Practice
        </p>
        <img
          src={convexitythumbnail}
          className='vascular-slide'
          onClick={() => {
            setVideoSixteen(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          Destination Closure: Strategies for Wound Closure Exploring Bioactive Glass Wound Care
        </p>
        <img
          src={dfuposter}
          className='vascular-slide'
          onClick={() => {
            setVideoFifteen(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          Translating Convexity Characteristics into Successful Clinical Outcomes: New Evidence to Guide Practice
        </p>
        <img
          src={msgeneric}
          className='vascular-slide'
          onClick={() => {
            setVideoFourteen(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          How Do I Choose the Right Dressing? A-Z of Dressing Technology
        </p>
        <img
          src={bioactiveglassposter}
          className='vascular-slide'
          onClick={() => {
            setVideoThirteen(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          All Roads Lead to Healing: Mastering Wound Bed Preparation
        </p>
        <img
          src={woundbedprepposter}
          className='vascular-slide'
          onClick={() => {
            setVideoTwelve(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
         
         Global Innovation in Wound Care Summit Series<br />
         <br />
         Part 4: What Do I Need to Know About Skin Substitutes?
       </p>
       <img
         src={skinsubsposter}
         className='vascular-slide'
         onClick={() => {
           setVideoEleven(true);
           document.documentElement.scrollTop = 500;
         }}
       ></img>
        <p className='video-one-title'>
         
         Global Innovation in Wound Care Summit Series<br />
         <br />
         Part 3: Mastering Burns Management: A Paradigm Shift in Treatment Management
       </p>
       <img
         src={summit3poster}
         className='vascular-slide'
         onClick={() => {
           setVideoTen(true);
           document.documentElement.scrollTop = 500;
         }}
       ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          Lessons From Space for Wound Care
        </p>
        <img
          src={space_slide}
          className='vascular-slide'
          onClick={() => {
            setVideoNine(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
         
          Global Innovation in Wound Care Summit Series<br />
          <br />
          Part 2: Biofilm Masterclass and Interactive Patient Clinic
        </p>
        <img
          src={dates_slide}
          className='vascular-slide'
          onClick={() => {
            setVideoEight(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          Clinical Challenges and Solutions in Palliative Wound Management
        </p>
        <img
          src={palliative_slide}
          className='vascular-slide'
          onClick={() => {
            setVideoSeven(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>

        <p className='video-one-title'>
         
          Global Innovation in Wound Care Summit Series<br />
          <br />
          Part 1: How to Improve Patient Outcomes: Evidence-Based Technology
        </p>
        <img
          src={dates_slide}
          className='vascular-slide'
          onClick={() => {
            setVideoSix(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          Getting the Best Patient Outcomes in Chronic Venous Disease; From
          Micro to Macro
        </p>
        <img
          src={vascular_slide}
          className='vascular-slide'
          onClick={() => {
            setVideoOne(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          All Your Questions Answered: Surgical Site Infection (SSI)
        </p>
        <img
          src={ssi_slide}
          className='vascular-slide'
          onClick={() => {
            setVideoTwo(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          All Your Questions Answered: Practical Topical Oxygen Therapy
        </p>
        <img
          src={oxygen_slide}
          className='vascular-slide'
          onClick={() => {
            setVideoThree(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
         <p className='video-one-title'>
          <span
            style={{
              fontStyle: 'italic',
              color: '#ec3b83',
              marginRight: '.3rem',
            }}
          >
            MasterSeries
          </span>{' '}
          60 Minutes Interactive <br />
          <br />
          All Your Questions Answered: Pressure Injury
        </p>
        <img
          src={pressure_slide}
          className='vascular-slide'
          onClick={() => {
            setVideoFour(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
        Wound Masterclass September Issue: Journal Club Interactive <br />
          <br />
          
        </p>
        <img
          src={jci}
          className='vascular-slide'
          onClick={() => {
            setVideoFive(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
      </section>
    );
  } else if (videoOne && !videoTwo) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/5CLhEEY5h70'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setVideoOne(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (videoTwo && !videoOne) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/d2UY2WeSyG4'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setVideoTwo(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (videoThree && !videoOne && !videoTwo) {
    return (
    <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/tC0c93WqWQ4'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoThree(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
  );
  } else if (videoFour && !videoOne && !videoTwo && !videoThree) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/jSv_wyz4Kkk'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setVideoFour(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (videoFive && !videoFour && !videoOne && !videoTwo && !videoThree) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/fs8msiBj0dg'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setVideoFive(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (videoSix && !videoFive && !videoFour && !videoOne && !videoTwo && !videoThree) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/6IYX0kwAxts'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setVideoSix(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (!videoSix && !videoFive && !videoFour && !videoThree && !videoTwo && !videoOne && videoSeven) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://www.youtube.com/watch?v=p9calemkHRk'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setVideoSeven(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    )
  } else if (!videoSix && !videoFive && !videoFour && !videoThree && !videoTwo && !videoOne && !videoSeven && videoEight) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/2Z8DxnDfzBg'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoEight(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>

    )
  } else if (!videoSix && !videoFive && !videoFour && !videoThree && !videoTwo && !videoOne && !videoSeven && !videoEight && videoNine) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/vfnQeVdxQZU'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoNine(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>

    )
  } else if (!videoSix && !videoFive && !videoFour && !videoThree && !videoTwo && !videoOne && !videoSeven && !videoEight && !videoNine && videoTen) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/jlYUFh59VRc'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoTen(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>

    )
  } else if (videoEleven) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/9EenDPCwNYo'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoEleven(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  } else if (videoTwelve) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/2KhprJ9eqJk'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoTwelve(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  }
  else if (videoThirteen) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/WTcZMb7hins'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoThirteen(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  } else if (videoFourteen) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/Bwr3uFyKhtY'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoFourteen(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  } else if (videoFifteen) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/GmPOpS_payw'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoFifteen(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  } else if (videoSixteen) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/QrtLqJpu_Bc'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoSixteen(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  } else if (videoSeventeen) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/eTUpJdkPHWA'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoSeventeen(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  } else if (videoEighteen) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/m69s48n-mIs'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoEighteen(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  }else if (videoNineteen) {
    return (
      <section className='video-one-page-container'>
      <section className='video-one-container'>
        <ReactPlayer
          url='https://youtu.be/LYmpVHbWEZ8'
          width='100w'
          playing='true'
          controls='true'
        />
      </section>
      <div
        className='go-back-button-background-video'
        onClick={() => {
          setVideoNineteen(false);
          document.documentElement.scrollTop = 500;
        }}
      >
        <span className='go-back-button-video'>Go Back</span>
      </div>
    </section>
    )
  }
};

export default Video;
