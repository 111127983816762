// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import graphic1 from '../images/wu desktop.webp';
import graphic2 from '../images/timmons desktop.webp';
import graphic3 from '../images/space desktop.webp';
import graphic4 from '../images/yarbrough-desktop.webp';


// Import Swiper styles
import 'swiper/css';

export default () => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, A11y, Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      autoplay
      loop={true}
      pagination={{ clickable: true }}
     
      className='swiper'
      
      // onSwiper={(swiper) => console.log(swiper)}
      // onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide><div className='slides'><img src={graphic1} style={{flex: 1, width: '840px'}}></img></div></SwiperSlide>
      <SwiperSlide><div className='slides'><img src={graphic2} style={{flex: 1, width: '840px'}}></img></div></SwiperSlide>
      <SwiperSlide><div className='slides'><img src={graphic3} style={{flex: 1,  width: '840px'}}></img></div></SwiperSlide>
      {/* <SwiperSlide><div className='slides'><img src={graphic4} style={{flex: 1,  width: '840px'}}></img></div></SwiperSlide> */}



      ...
    </Swiper>
  );
};