import '../styles/register.css';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { wmcApi } from '../utils/api';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import registering from '../images/registering.gif';
import { isFirefox } from 'react-device-detect';
import offer from '../images/register-offer.webp';
import offer_mobile from '../images/register-offer-mobile.webp';

const Register = ({
  auth,
  setAuth,
  mobile,
  loginPage,
  setLoginPage,
  registerPage,
  setRegisterPage,
  subscribePage,
  setSubscribePage,
  setNotOptions,
  setAccessToken,
  userFirstName,
  setUserFirstName,
  loggedInMsg,
  setLoggedInMsg,
  setPodcastLogo
}) => {

  useEffect(() => {
    setPodcastLogo(true);
  }, []);
  useEffect(() => {
    setRegisterPage(true);
    setNotOptions(false);
    if (loginPage) {
      setLoginPage(false);
    } else if (subscribePage) {
      setSubscribePage(false);
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 700);
  }, []);
  const navigate = useNavigate();
  const [registerAction, setRegisterAction] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [registerDetails, setRegisterDetails] = useState({});
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [passwordInputOne, setPasswordInputOne] = useState('');
  const [passwordInputTwo, setPasswordInputTwo] = useState('');
  const [roleInput, setRoleInput] = useState('');
  const [postalCodeInput, setPostalCodeInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [checkboxMsg, setCheckboxMsg] = useState(false);
  const [banned, setBanned] = useState(false);

  useEffect(() => {
    if (login) {
      const loginDetails = { email: email, password: password };
      wmcApi
        .post('/login', loginDetails)
        .then((response) => {
          setLoading(false);
          setAccessToken(response.data.accessToken);
          setAuth(true);
          Cookies.set('user', userFirstName, { expires: 7 });
          setLogin(false);
          setLoading(false);
          navigate('/Home');
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [login]);

  useEffect(() => {
    if (registerAction && checkbox) {
      wmcApi
        .post('/register', registerDetails)
        .then((response) => {
          setEmail(registerDetails.email);
          setPassword(registerDetails.password);
          setUserFirstName(
            registerDetails.firstName.charAt(0).toUpperCase() +
              registerDetails.firstName.slice(1)
          );
          setRegisterDetails({});
          setRegisterAction(false);
          setCheckbox(false);
          setFirstNameInput('');
          setLastNameInput('');
          setEmailInput('');
          setPasswordInputOne('');
          setPasswordInputTwo('');
          setRoleInput('');
          setPostalCodeInput('');
          setLogin(true);
        })
        .catch((err) => {
          setLoading(false);
          setCheckbox(false);
          setRegisterAction(false);
          if (!banned) {
            setAlreadyRegistered(true);
          }
        });
    } else if (registerAction && !checkbox) {
      setLoading(false);
      setCheckboxMsg(true);
      setRegisterAction(false);
    }
  }, [registerAction]);
  const scrollToTop = () => {
    if (
      firstNameInput &&
      lastNameInput &&
      emailInput &&
      passwordInputOne &&
      roleInput &&
      postalCodeInput
    ) {
      document.documentElement.scrollTop = 0;
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();

    if (
      (emailInput.toLowerCase().includes('omniamed') && checkbox) ||
      (emailInput.toLowerCase().includes('markallengroup') && checkbox) ||
      (emailInput.toLowerCase().includes('csmphelan@hotmail.com') && checkbox)
    ) {
      setBanned(true);
    } else {
      setRegisterDetails({
        firstName: firstNameInput.trim(),
        lastName: lastNameInput.trim(),
        email: emailInput.toLowerCase().trim(),
        password: passwordInputOne.trim(),
        role: roleInput,
        postalCode: postalCodeInput,
      });
    }
    if (checkbox) {
      setLoading(true);
      setTimeout(() => setRegisterAction(true), 1000);
    } else {
      setCheckboxMsg(true);
    }
  };

  const changeHandlerFirstName = (e) => {
    setFirstNameInput(e.target.value);
  };
  const changeHandlerLastName = (e) => {
    setLastNameInput(e.target.value);
  };
  const changeHandlerEmail = (e) => {
    setEmailInput(e.target.value);
  };
  const changeHandlerPasswordOne = (e) => {
    setPasswordInputOne(e.target.value);
  };
  const changeHandlerPasswordTwo = (e) => {
    setPasswordInputTwo(e.target.value);
  };
  const changeHandlerRole = (e) => {
    setRoleInput(e.target.value);
  };
  const changeHandlerPostalCode = (e) => {
    setPostalCodeInput(e.target.value);
  };
  const handleCheck = () => {
    if (!checkbox) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  };

  const regSelectStyle = mobile ? 'register-select-mobile' : 'register-select';
  const checkboxLabel = mobile ? 'checkbox-label-mobile' : 'checkbox-label';
  const privacySpan = mobile ? 'privacy-span-mobile' : 'privacy-span';
  const regSelectCol =
    isFirefox && !mobile ? 'reg-select-firefox' : 'reg-select-not-firefox';
  const titleStyle = mobile ? 'mobile-title' : 'register-title';
  // const offerVersion = mobile ? offer_mobile : offer;
  // const offerStyle = mobile ? 'offer-mobile' : 'offer';
  if (!loading && !auth && !alreadyRegistered && !checkboxMsg && !banned) {
    return (
      <>
        <section className='register-page-container'>
          <section className='register-container'>
            <div className={titleStyle}>REGISTER</div>
            <div className='register-box'>
              <form
                className='register-form'
                id='register'
                onSubmit={submitHandler}
              >
                <input
                  required
                  type='text'
                  className='register-input'
                  id='register-top-input'
                  placeholder='First Name'
                  onChange={changeHandlerFirstName}
                  value={firstNameInput}
                ></input>
                <input
                  required
                  type='text'
                  className='register-input'
                  placeholder='Last Name'
                  onChange={changeHandlerLastName}
                  value={lastNameInput}
                ></input>

                <input
                  required
                  type='text'
                  className='register-input'
                  placeholder='Email'
                  onChange={changeHandlerEmail}
                  value={emailInput}
                ></input>

                <input
                  required
                  type='password'
                  className='register-input'
                  placeholder='Password'
                  minLength='6'
                  onChange={changeHandlerPasswordOne}
                  value={passwordInputOne}
                ></input>

                <select
                  required
                  className={regSelectStyle}
                  onChange={changeHandlerRole}
                  value={roleInput}
                  id={regSelectCol}
                >
                  <option value='' id='select-title'>
                    Profession / Role
                  </option>
                  <option value='Doctor'>Doctor</option>
                  <option value='Nurse'>Nurse</option>
                  <option value='Healthcare Assistant'>
                    Healthcare Assistant
                  </option>
                  <option value='Researcher'>Researcher</option>
                  <option value='Student'>Student</option>
                  <option value='Other'>Other</option>
                </select>
                <input
                  required
                  type='text'
                  className='register-input'
                  placeholder='Postal Code'
                  id='register-bottom-input'
                  onChange={changeHandlerPostalCode}
                  value={postalCodeInput}
                ></input>
              </form>
            </div>
            <button
              className='form-button'
              form='register'
              id='register-continue'
              onClick={() => {
                scrollToTop();
              }}
            >
              CONTINUE
            </button>
            <div className='checkbox-container'>
              <input
                type='checkbox'
                id='privacy-policy-checkbox'
                onChange={() => {
                  handleCheck();
                }}
              ></input>
            </div>
            <div className='privacy-checkbox-container'>
              <label
                htmlFor='privacy-policy-checkbox'
                form='register'
                className={checkboxLabel}
              >
                I have read and accept the{' '}
                <Link to='/PrivacyPolicy'>
                  <span
                    className={privacySpan}
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    privacy policy{' '}
                  </span>
                </Link>
                and
                <Link to='/TermsAndConditions'>
                  <span
                    className={privacySpan}
                    onClick={() => {
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    {' '}
                    terms and conditions.
                  </span>
                </Link>
              </label>
            </div>
            <div className='registered-question'>
              Already have an account?{' '}
              <Link to='/Login'>
                <span
                  className='email-links'
                  onClick={() => {
                    document.documentElement.scrollTop = 0;
                  }}
                >
                  Log in.
                </span>
              </Link>
            </div>
          </section>
          {/* <img src={offerVersion} className={offerStyle}></img> */}
        </section>
      </>
    );
  } else if (auth) {
    if (!loggedInMsg) {
      return <></>;
    } else if (loggedInMsg)
      return (
        <section>
          <div className='error-message'>
            <div className='message-box'>
              <p>You are already registered.</p>
            </div>
          </div>
          <p className='register-question'>
            Go{' '}
            <Link to='/Home'>
              <span
                className='email-links'
                onClick={() => {
                  document.documentElement.scrollTop = 0;
                }}
              >
                back to homepage and latest issue.
              </span>
            </Link>
          </p>
        </section>
      );
  } else if (loading) {
    return (
      <div className='registering-container'>
        <img src={registering} className='registering'></img>
      </div>
    );
  } else if (alreadyRegistered) {
    setTimeout(() => setAlreadyRegistered(false), 1700);
    return (
      <div className='error-message'>
        <div className='message-box'>
          <p>You are already registered.</p>
        </div>
      </div>
    );
  } else if (banned) {
    setTimeout(() => setBanned(false), 1700);
    return (
      <div className='error-message'>
        <div className='message-box'>
          <p>Error. Please try again later.</p>
        </div>
      </div>
    );
  } else if (checkboxMsg) {
    setTimeout(() => setCheckboxMsg(false), 1700);
    return (
      <div className='error-message'>
        <div className='message-box'>
          <p>Please check the privacy policy box.</p>
        </div>
      </div>
    );
  }
};

export default Register;
