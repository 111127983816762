import '../styles/articles.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Articles = ({ setNotOptions, mobile, setPodcastLogo }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);
  useEffect(() => {
    setPodcastLogo(true);
  }, []);
  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const titleStyle = mobile ? 'mobile-article-title' : 'article-title';

  return (
    <>
    <section className='december-container'>
      <h1 className={titleStyle}>Articles from December 2023</h1>
      <div className='articles-box'>
      <p className='article-list-item'>
    <a href='https://issuu.com/woundmasterclass/docs/searching_for_the_resilient_option_in_healing?fr=sOTY0MTcxNTY1NzE'>
    Searching for the Resilient Option in Healing  <span className='article-item-divider'>|</span>  Dr Negin Shamsian
        </a>
        <br/>
        <br/>
         
        <a href='https://issuu.com/woundmasterclass/docs/fluid_shifts_in_space_flight_analogues_and_terrest/2?fr=sNDIxNTcxNTY1Nzc'>
        Fluid Shifts in Space Flight Analogues and Terrestrial Wound Clinic Applications  <span className='article-item-divider'>|</span>  Dr M. Mark Melin, Dr Heather Barnhart, Mr Frank Aviles, Ms Sabrina Ginsburg
        </a>
        <br/>
        <br/>      
        <a href='https://issuu.com/woundmasterclass/docs/optimizing_non-healing_venous_leg_u_46195e6cb96a43/2?fr=sZWM2ZDcxNTY1Nzc'>
        Optimizing Non-Healing Venous Leg Ulcers and Diabetic Foot Ulcers - Standard of Care vs Amniotic Membrane  <span className='article-item-divider'>|</span>  Dr Alton R. Johnson, Mr Shenlone Wu, Ms Briana Lay
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/evolution_of_dressing_change_frequency_for_patient/2?fr=sMTliYjcxNTY1Nzc'>
        Evolution of Dressing Change Frequency for Patients with Wounds  <span className='article-item-divider'>|</span>  Dr Negin Shamsian
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/jump-starting_healing_in_venous_leg_ulcers_-_shoul/2?fr=sOGJkNjcxNTY1Nzc'>
        Jump-starting Healing in Venous Leg Ulcers: Should Electrical Stimulation be Considered in Synergy with Compression Therapy?  <span className='article-item-divider'>|</span>  Dr Keith Gordon Harding
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/a_versatile_framework_to_quickly_implement_wound_c/2?fr=sMTY1ZTcxNTY1Nzc'>
        A Versatile Framework to Quickly Implement Wound Care-Specific, Role-Based Competency Programs  <span className='article-item-divider'>|</span>  Dr Elaine H. Song, Ms Catherine T. Milne, Ms Tiffany Hamm, Ms Nataliya Lebedinskaya, Ms Janis Prado, Mr Jeff Mize
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/how_to_build_solid_foundations_to_support_the_trea/2?fr=sZWUyYjcxNTY1Nzc'>
        How To Build Solid Foundations to Support the Treatment and Management of Chronic Wounds: A Clinician's Guide  <span className='article-item-divider'>|</span>  Mr John Timmons, Assoc Prof Matthew Malone, Prof Dr Joachim Dissemond
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/global_innovation_in_wound_care_summit_series_-_pa/2?fr=sOTRiNjcxNTY1Nzc'>
        Global Innovation in Wound Care Summit Series - Part 2 - Biofilm Masterclass
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/global_innovation_in_wound_care_summit_series_-_wh/2?fr=sZDY3NjcxNTY1Nzc'>
        Global Innovation in Wound Care Summit Series - What Do I Need to Know About Skin Substitutes?
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/global_innovation_in_wound_care_summit_series_-_wh/2?fr=sZDY3NjcxNTY1Nzc'>
        Masterseries 60 Minutes Interactive: Clinical Challenges and Solutions in Palliative Wound Management
        {/* wrong link */}
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/pillai_-_december_2023_29-11-2023_/2?fr=sMWMyMzcxNTY1Nzc'>
        What Is the Role of Platelet-Derived Biologics?  <span className='article-item-divider'>|</span>  Prof Anand Pillai, Dr Vish Kumar
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/all_roads_lead_to_healing_-_mastering_wound_bed_pr/2?fr=sZDY3NTcxNTY1Nzc'>
        All Roads Lead to Healing - Mastering Wound Bed Preparation
        </a>
        <br/>
        <br/>
        
        <br/>
        <br/>
       
        
        {/* <h1 className='guides-title'><span style={{ fontStyle: 'italic' }}>Masterclass </span>Guides</h1>
        <a href='https://issuu.com/woundmasterclass/docs/masterclass_guides_-_moisture_associated_skin_dama/2?fr=sODVlNDU5NjMzODQ'>
        Masterclass Guides: Moisture Associated Skin Damage
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/decellularized_fish_skin_technology_-_burns/2?fr=sZDkyMjU5NjMzODQ'>
        Masterclass Guides: Decellularized Fish Skin Technology: Burns
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/decellularized_fish_skin_technology/2?fr=sYWM4NDU5NjMzODQ'>
        Masterclass Guides: Decellularized Fish Skin Technology
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/lymphedema_in_clinical_practice/2?fr=sMjk3MTU5NjMzODQ'>
        Masterclass Guides: Lymphedema in Clinical Practice
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/critical_limb_ischaemia/2?fr=sYzc3YzU5NjMzODQ'>
        Masterclass Guides: Critical Limb Ischaemia
        </a>
        <br/>
        <br/> */}
        </p>
      </div>
    </section>
    <section className='march-container'>
      <h1 className={titleStyle}>Articles from March 2023</h1>
      <div className='articles-box'>
      <p className='article-list-item'>
    <a href='https://issuu.com/woundmasterclass/docs/humans_vs_machines_-_are_we_becoming_replaceable?fr=sZTU1NzU5NjMzOTk'>
    Humans vs Machines: Are We Becoming Replaceable?  <span className='article-item-divider'>|</span>  Dr Negin Shamsian
        </a>
        <br/>
        <br/>
         
        <a href='https://issuu.com/woundmasterclass/docs/how_does_a_wound_dressing_with_sensors_monitor_the/2?fr=sZmVmMjU5NjMzODQ'>
        How Does a Wound Dressing With Sensors Monitor the Healing Process?  <span className='article-item-divider'>|</span>  Mr Armin Haas, Prof Kai-Uwe Zirk
        </a>
        <br/>
        <br/>      
        <a href='https://issuu.com/woundmasterclass/docs/limitations_of_applying_summary_results_of_clinica?fr=sYjM2ZTU5NjMzODM'>
        Limitations of Applying Summary Results of Clinical Trials to Individual Patients: The Need for Risk Stratification  <span className='article-item-divider'>|</span>  Mr Zwelithini Tunyiswa
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/the_dawning_of_a_new_horizon_for_venous_leg_ulcer_/2?fr=sNDU3YTU5NjM0MDI'>
        The Dawning of a New Horizon For Venous Leg Ulcer Treatment  <span className='article-item-divider'>|</span>  Mr Bernard Ross
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/synergistic_effects_of_pure_hypochlorous_acid_and_/2?fr=sYTQxZTU5NjMzODI'>
        Synergistic Effects of Pure Hypochlorous Acid and Ovine Forestomach in Chronic Venous Disease  <span className='article-item-divider'>|</span>  Dr Monika Gloviczki, Dr M. Mark Melin, Dr Peter Gloviczki, Dr Abigail Chaffin, Dr Lee Ruotsi
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/what_is_the_role_of_fish_skin_graft_in_combat_inju/2?fr=sY2Y4ZTU5NjM0MDI'>
        What is the Role of Fish Skin Graft in Combat Injuries in Austere Conditions?  <span className='article-item-divider'>|</span>  Dr Fouad Reda, Dr Hilmar Kjartansson, Dr Steven Jeffery
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/clinical_experience_of_omega_3_fish_graft_in_full_/2?fr=sMDBkZjU5NjMzODQ'>
        Clinical Experience of Omega 3 Fish Graft in Full Thickness Wounds  <span className='article-item-divider'>|</span>  Dr Ariel M. Aballay
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/lower_extremity_lymphedema_-_an_often_overlooked_a/2?fr=sZDk0YzU5NjMzODQ'>
        Lower Extremity Lymphedema; An Often Overlooked and Undermanaged Condition  <span className='article-item-divider'>|</span>  Dr Tristan Pannella, Dr Karen Andrews, Dr Heather Barnhart, Dr Joseph Raffetto, Dr Wei Chen, Dr Daniel Miller, Dr Michael Shao, Dr M. Mark Melin
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/what_is_the_role_of_point-of-care_fluorescence_ima/2?fr=sNDNiNTU5NjM0MDI'>
        What is the Role of Point-Of-Care Fluorescence Imaging for Bacterial Load Identification in Diabetic Foot Ulcers?  <span className='article-item-divider'>|</span>  Dr David G. Armstrong, Dr Michael E. Edmonds, Dr Thomas E. Serena
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/a_novel_concept_for_treating_large_soft_tissue_def/2?fr=sZWU0ZTU5NjMzODQ'>
        A Novel Concept for Treating Large Soft Tissue Defects After Necrotizing Soft Tissue Infection of the Back  <span className='article-item-divider'>|</span>  Dr Marcus F. Yarbrough
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/getting_the_best_patient_outcomes_in_chronic_venou/2?fr=sNzFlMDU5NjM0MDI'>
        Masterseries 60 Minutes Interactive: Getting the Best Patient Outcomes in Chronic Venous Disease; From Micro to Macro  <span className='article-item-divider'>|</span>  Dr Negin Shamsian, Dr Abigail Chaffin, Dr M. Mark Melin, Dr Lee Ruotsi, Dr Peter Gloviczki, Dr Monika Gloviczki
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/topical_oxygen_therapy_-_all_your_questions_answer/2?fr=sMzc1ZTU5NjM0MDI'>
        Masterseries 60 Minutes Interactive: Topical Oxygen Therapy: All Your Questions Answered  <span className='article-item-divider'>|</span>  Dr Negin Shamsian, Dr Matthew G. Garoufalis, Mr Frank Aviles, Ms Kara Couch, Dr Anil Hingorani, Dr Paul Haser
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/surgical_site_infection_ssi_-_all_your_questions/2?fr=sZTI3YzU5NjM0MDI'>
        Masterseries 60 Minutes Interactive: Surgical Site Infection (SSI): All Your Questions Answered  <span className='article-item-divider'>|</span>  Dr Negin Shamsian, Dr Jonathan Johnson, Dr Windy Cole, Dr M. Mark Melin, Dr Michael Magro, Dr Huseyin Kemal Rasa
        </a>
        <br/>
        <br/>
       
        
        <h1 className='guides-title'><span style={{ fontStyle: 'italic' }}>Masterclass </span>Guides</h1>
        <a href='https://issuu.com/woundmasterclass/docs/masterclass_guides_-_moisture_associated_skin_dama/2?fr=sODVlNDU5NjMzODQ'>
        Masterclass Guides: Moisture Associated Skin Damage
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/decellularized_fish_skin_technology_-_burns/2?fr=sZDkyMjU5NjMzODQ'>
        Masterclass Guides: Decellularized Fish Skin Technology: Burns
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/decellularized_fish_skin_technology/2?fr=sYWM4NDU5NjMzODQ'>
        Masterclass Guides: Decellularized Fish Skin Technology
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/lymphedema_in_clinical_practice/2?fr=sMjk3MTU5NjMzODQ'>
        Masterclass Guides: Lymphedema in Clinical Practice
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/critical_limb_ischaemia/2?fr=sYzc3YzU5NjMzODQ'>
        Masterclass Guides: Critical Limb Ischaemia
        </a>
        <br/>
        <br/>
        </p>
      </div>
    </section>
    <section className='december-container'>
      <h1 className={titleStyle}>Articles from December 2022</h1>
      <div className='articles-box'>
      <p className='article-list-item'>
    <a href='https://issuu.com/woundmasterclass/docs/the_road_less_travelled?fr=sNzJkNjU4Mzg5NDA'>
    The Road Less Travelled  <span className='article-item-divider'>|</span>  Miss Negin Shamsian
        </a>
        <br/>
        <br/>
         
        <a href='https://issuu.com/woundmasterclass/docs/accelerated_healing_and_advanced_wound_care_what_i/2?fr=sNGMwYzU4Mzg5NTA'>
        Accelerated Healing and Advanced Wound Care: What is the Role of Wireless, Closed-Loop, Smart Bandages With Integrated Sensors and Stimulators?  <span className='article-item-divider'>|</span>  Mr Artem Trotsyuk
        </a>
        <br/>
        <br/>      
        <a href='https://issuu.com/woundmasterclass/docs/optimizing_management_of_lymphedema/2?fr=sYzE0ZjU4Mzg5NTA'>
        Optimizing Management of Lymphedema  <span className='article-item-divider'>|</span>  Dr M. Mark Melin
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/all_evidence_is_not_created_equal_what_makes_good_/2?fr=sOGI2YTU4Mzg5NTA'>
        All Evidence Is Not Created Equal: What Makes Good Clinical Data?  <span className='article-item-divider'>|</span>  Ms Kara Couch
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/the_safe_guide_to_debridement_in_the_challenging_c/2?fr=sMDU0NDU4Mzg5NTA'>
        The Safe Guide to Debridement in the Challenging Clinical Setting  <span className='article-item-divider'>|</span>  Dr Aliza Lee
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/closing_the_gap_of_health_disparity_in_the_wound_c/2?fr=sZTc3ZjU4Mzg5NTA'>
        Closing the Gap of Health Disparity in the Wound Care Industry With the Use Long Wave Infrared Thermography in Dark Skin Individuals  <span className='article-item-divider'>|</span>  Mr Frank Aviles
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/mixed_aetiology_lower_extremity_ulcers_the_role_of/2?fr=sZDRiODU4Mzg5NTA'>
        Mixed Aetiology Lower Extremity Ulcers: The Role of Omega-3 Products  <span className='article-item-divider'>|</span>  Dr Windy Cole
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/offloading_the_comprehensive_guide_/2?fr=sOTQwNzU4Mzg5NTA'>
        Offloading: The Comprehensive Guide  <span className='article-item-divider'>|</span>  Dr Anthony Tickner
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/alligator-derived_hyaluronic_acid_bacteriostatic_a/2?fr=sZGE5NjU4Mzg5NTA'>
        Alligator-Derived Hyaluronic Acid: Bacteriostatic and Fungastatic Properties Against Pathogens  <span className='article-item-divider'>|</span>  Dr Mitch Sanders
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/the_future_of_3d_printed_biofilms_for_in_vitro_and/2?fr=sNDFjYzU4Mzg5NTA'>
        The Future of 3D Printed Biofilms for In Vitro and In Vivo Wound Infection Models  <span className='article-item-divider'>|</span>  Dr Mitch Sanders, Ms Mia Hanna
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/wound_biopsies_of_atypical_wound_presentations_lea/2?fr=sYWM2ZDU4Mzg5NTA'>
        Wound Biopsies of Atypical Wound Presentations Lead to the Diagnosis of Rare Disease States: A Case Series  <span className='article-item-divider'>|</span>  Dr Windy Cole
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/addressing_the_challenge_of_pressure_injuries/2?fr=sNmJiNTU4Mzg5NTA'>
        Addressing the Challenge of Pressure Injuries  <span className='article-item-divider'>|</span>  Ms Hayley Ryan
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/minimizing_the_legal_issues_around_pressure_injury/2?fr=sMzkxYzU4Mzg5NTA'>
        Minimizing the Legal Issues Around Pressure Injury Care  <span className='article-item-divider'>|</span>  Ms Kathleen Martin
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/improving_quality_of_life_using_a_novel_electrical/2?fr=sOWQzMzU4Mzg5NTA'>
        Improving Quality of Life Using a Novel Electrical Stimulation Therapy Device to Reduce Pain and Accelerate Healing in Two Patients With Very Different Underlying Aetiologies <span className='article-item-divider'>|</span>  Ms Claire Allan, Ms Liz Ovens
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/biosurgery_indications_contraindications_interac/2?fr=sZTI4OTU4Mzg5NTA'>
        Biosurgery: Indications, Contraindications, Interactions and Side-effects  <span className='article-item-divider'>|</span>  Dr Frank Stadler, Dr Ronald Sherman
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/biosurgery_application_and_dressing_technology/2?fr=sODFjOTU4Mzg5NTA'>
        Biosurgery: Application and Dressing Technology | Dr Frank Stadler  <span className='article-item-divider'>|</span>  Dr Frank Stadler, Dr Ronald Sherman
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/terminology_of_tendon_disorders/2?fr=sYWYyZTU4Mzg5NTA'>
        Terminology of Tendon Disorders  <span className='article-item-divider'>|</span>  Prof Anand Pillai, Dr Naeem Jagani
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/use_of_an_advanced_collagen_powder_to_close_non-he/2?fr=sYWY1MzU4Mzg5NTA'>
        Use of an Advanced Collagen Powder to Close Non-healing Post-amputation Wounds <span className='article-item-divider'>|</span>  Dr Alton R. Johnson, Dr Brennen O'Dell
        </a>
        <br/>
        <br/>
       
        
        <h1 className='guides-title'><span style={{ fontStyle: 'italic' }}>Masterclass </span>Guides</h1>
        <a href='https://issuu.com/woundmasterclass/docs/masterclass_guides_omeza_treatments_anhydrous_omeg/2?fr=sNGIzMzU4Mzg5NTA'>
        Masterclass Guides: Omeza® Treatments: Anhydrous Omega Therapies
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/masterclass_guides_-_topical_external_hemostat_-_o/2?fr=sZDkzMTU4Mzg5NTA'>
        Masterclass Guides: Topical External Hemostat - OMNI-STAT©
        </a>
        <br/>
        <br/>
        </p>
      </div>
    </section>
    <section className='september-container'>
      <h1 className={titleStyle}>Articles from SEPTEMBER 2022</h1>
      <div className='articles-box'>
      <p className='article-list-item'>
    <a href='https://issuu.com/woundmasterclass/docs/technology_is_a_useful_servant_but_a_dangerous_mas?fr=sMWQyYTU1NzEzNDk'>
    Technology Is a Useful Servant but a Dangerous Master  <span className='article-item-divider'>|</span>  Miss Negin Shamsian
        </a>
        <br/>
        <br/>
         
        <a href='https://issuu.com/woundmasterclass/docs/biotensegrity_-_its_application_to_tissue_function/2?fr=sMjM4ZTU1ODIxOTk'>
        Biotensegrity: Its Application to Tissue Function and Dysfunction  <span className='article-item-divider'>|</span>  Dr Heather Hettrick
        </a>
        <br/>
        <br/>      
        <a href='https://issuu.com/woundmasterclass/docs/flicking_the_switch_-_jump_starting_the_challengin/2?fr=sNzI4ZjU1ODIxOTk'>
        Flicking the Switch: Jump Starting the Challenging Wound  <span className='article-item-divider'>|</span>  Dr Adriano A. Mehl
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/navigating_the_current_wound_care_landscape_post-c/2?fr=sZjgyNzU1ODIxOTk'>
        Navigating the Current Wound Care Landscape Post-COVID-19  <span className='article-item-divider'>|</span>  Mr Tobe Madu
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/what_is_the_role_of_wireless_electr_0452cec24e284a/2?fr=sOGQ3NTU1ODIxOTk'>
        What is the Role of Wireless Electroceutical Dressings for Treatment of Venous Leg Ulcers  <span className='article-item-divider'>|</span>  Dr Windy Cole, Dr Mark Barakat
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/digitizing_the_future_of_wounds_-_what_are_the_cha?fr=sNDFjMTU1ODIyMDY'>
        Digitizing the Future of Wounds: What Are the Challenges?  <span className='article-item-divider'>|</span>  Mr Michael Oliver
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/digitizing_wound_assessment_-_unlocking_the_potent/2?fr=sNWZlYzU1ODIyMTA'>
        Digitizing Wound Assessment: Unlocking the Potential of the Wound Care Team  <span className='article-item-divider'>|</span>  Mr Robert D. J. Fraser, Mr Carlo Perez, Dr Robert Bartlett, Ms Amy Lorincz, Ms Amy Cassata, RN, Mr David Mannion, Dr José L. Ramírez-GarcíaLuna
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/clinical_evaluation_of_the_response_rate_to_a_cont/2?fr=sMGQxZDU1ODIyMTA'>
        Clinical Evaluation of the Response Rate to a Continuously Active, Single-use Electrical Stimulation Device in Static Non-Healing Wounds  <span className='article-item-divider'>|</span>  Mr Peter Kurz, Dr Robin Martin, Ms Gabriele Danner
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/the_impact_of_hba1c_levels_on_diabetic_foot_ulcers?fr=sOThhYjU1ODIyMTE'>
        Impact of HbA1c Levels on Diabetic Foot Ulcers Using Fish Skin Grafts  <span className='article-item-divider'>|</span>  Dr Ryan Huang, Alexis Chan, Shenmei Wu
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/the_application_of_omega3_fish_skin_graft_in_nutri/2?fr=sODliNDU1ODIyMTM'>
        The Application of Fish Skin Graft in Nutritional Skin Failure Syndrome: Two Case Studies  <span className='article-item-divider'>|</span>  Dr Naz Wahab
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/surgical_applications_of_intact_and_fragmented_fis/2?fr=sZDAzNDU1ODIyMTM'>
        Surgical Applications of Intact and Fragmented Fish Skin Grafts and NPWT for Lower Extremity Wounds: A Case Report  <span className='article-item-divider'>|</span>  Dr Rimvydas Statkus, Dr Deepali Darji, Dr Patrick A. McEneaney, Dr Joseph D. Rundell
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/hidradenitis_suppurativa_-_a_guide_to_surgical_man/2?fr=sYjk3ZjU1ODIyMTM'>
        Hidradenitis Suppurativa: A Guide to Surgical Management with Omega3-rich Fish Skin Grafts  <span className='article-item-divider'>|</span>  Dr Michael Romberg
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/the_top_ten_tips_for_optimizing_oxygen_therapy_in_/2?fr=sNTU2ZTU1ODIyMTM'>
        The Top Ten Tips For Optimizing Oxygen Therapy in Your Practice  <span className='article-item-divider'>|</span>  Dr Aliza Lee
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/oxygen_therapy_and_wound_management_-_thinking_out/2?fr=sNTQ1YzU1ODIyMTM'>
        Oxygen Therapy and Wound Management: Thinking Outside the Chamber <span className='article-item-divider'>|</span>  Mr Frank Aviles
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/addressing_the_challenge_of_complex_wound_defects_?fr=sMDkyNzU1ODIyMjU'>
        Addressing the Challenge of Complex Wound Defects: The Scope of a Dermal Matrix  <span className='article-item-divider'>|</span>  Dr Ali Shahmoradi
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/mastering_the_art_of_compression_in_7f3733df599264/2?fr=sMzkzMzU1ODIyMjY'>
        Mastering the Art of Compression in Healing Venous Wounds  <span className='article-item-divider'>|</span>  Ms Kara Couch, Dr Lucian G. Vlad, Mr Evan Call
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/clinical_practice_guidelines_for_pressure_ulcer_in/2?fr=sZjNjOTU1ODIyMjY'>
        Clinical Practice Guidelines for Pressure Ulcer/ Injury Prevention and Treatment: Opportunities and Challenges  <span className='article-item-divider'>|</span>  Dr Jan Kottner
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/combination_therapies_-_does_ecm_replacement_and_n/2?fr=sNGIzMTU1ODIyMjY'>
        Combination of ECM Replacement and NPWT Speeds up the Healing in Challenging Stage Iv Pressure Ulcers <span className='article-item-divider'>|</span>  Dr Richard Simman
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/wound_aetiologies_patient_characteristics_and_he/2?fr=sNDUwOTU1ODIyMjY'>
        Wound Aetiologies, Patient Characteristics, and Healthcare Settings Amenable to Maggot Therapy  <span className='article-item-divider'>|</span>  Mr Frank Stadler, Dr Ronald Sherman
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/charcot_vs_osteomyelitis_-stop_think_treat/2?fr=sY2Q2YjU1ODIyMjY'>
        Charcot Vs Osteomyelitis: Stop, Think & Treat  <span className='article-item-divider'>|</span>  Prof Anand Pillai, Dr Ahmad Bilal
        </a>
        <br/>
        <br/>
        </p>
      </div>
    </section>
    <section className='june-container'>
    <h1 className={titleStyle}>Articles from JUNE 2022</h1>
    <div className='articles-box'>
    <p className='article-list-item'>
    <a href='https://issuu.com/woundmasterclass/docs/you_can_t_start_a_fire_without_a_spark_-_introduct?fr=sY2VhODU1NzEzNDk'>
        You Can't Start a Fire Without A Spark: Introduction to Wound Masterclass  <span className='article-item-divider'>|</span>  Miss Negin Shamsian
        </a>
        <br/>
        <br/>
         
        <a href='https://issuu.com/woundmasterclass/docs/unlocking_the_door_to_global_collaboration_in_woun/2?fr=sZTY4NDU1NzEzMzE'>
        Unlocking the Door to Global Collaboration in Wound Care  <span className='article-item-divider'>|</span>  Mr Frank Aviles
        </a>
        <br/>
        <br/>      
        <a href='https://issuu.com/woundmasterclass/docs/wound_healing_-_past_present_and_future_-_is_it_t/2?fr=sNzg4ZjU1NzEzNDM'>
        Wound Healing: Past, Present and Future; Is It Time for a Treatment Paradigm Shift?  <span className='article-item-divider'>|</span>  Dr Kenneth Burhop
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/unravelling_the_mysteries_of_the_wound_healing_sys/2?fr=sZmI3NjU1NzEzMzE'>
        Unravelling the Mysteries of the Wound Healing System  <span className='article-item-divider'>|</span>  Mr Harm Jaap Smit
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/how_decentralized_and_hybrid_trials_can_drive_effi/2?fr=sYTFlMzU1NzEyNzk'>
        How Decentralized and Hybrid Trials Can Drive Efficiencies Beyond the Pandemic  <span className='article-item-divider'>|</span>  Mr Nico O’Kuinghttons
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/a_view_beyond_the_horizon_-_the_re-emergence_of_mi/2?fr=sNjg1OTU1NzEyNTM'>
        A View Beyond the Horizon; The Re-Emergence of Micronized Purified Flavonoid Fractions for Venous Leg Ulcer Management  <span className='article-item-divider'>|</span>  Dr M. Mark Melin, Dr Monika Lecomte Gloviczki
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/debridement_-_a_concise_guide/2?fr=sYTQyYTU1NzEyNzk'>
        Debridement: A Concise Guide  <span className='article-item-divider'>|</span>  Miss Negin Shamsian
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/vacuumed_space_technology_-_a_far-fetched_dream_or?fr=sZjUxMjU1NzEzMzg'>
        Vacuumed Space Technology: A Far-fetched Dream or a Life Changing Technology?  <span className='article-item-divider'>|</span>  Ms Liezl Naude
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/what_is_the_role_of_electrical_stimulation_therapy/2?fr=sMzA3OTU1NzEzNDM'>
        What Is the Role of Electrical Stimulation Therapy?  <span className='article-item-divider'>|</span>  Ms Liz Ovens
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/optimizing_wound_outcomes_in_surgery_-_top_ten_tip?fr=sM2M4YzU1NzEyOTk'>
        Optimizing Wound Outcomes in Surgery: Top Ten Tips  <span className='article-item-divider'>|</span>  Miss Negin Shamsian, Mr Andrew Diver
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/optimizing_oxygen_therapy_in_your_clinical_wound_p/2?fr=sNDk1MDU1NzEyNzk'>
        Optimizing Oxygen Therapy in Your Clinical Wound Practice  <span className='article-item-divider'>|</span>  Dr Aliza Lee
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/pressure_ulcer_risk_and_surgery_-_practice_conside?fr=sNDYxMzU1NzEyOTk'>
        Pressure Ulcer Risk and Surgery: Practice Considerations  <span className='article-item-divider'>|</span>  Prof Declan Patton, Prof Zena Moore, Dr Pinar Avsar, Ms Hannah Wilson
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/cross-linked_extracellular_matrix_with_phmb_to_man?fr=sYjY5MDU1NzEyNzE'>
        Cross-Linked ExtraCellular Matrix With PHMB to Manage Lower Extremity Wounds  <span className='article-item-divider'>|</span>  Dr Windy Cole, Dr Janina Krumbeck
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/the_role_of_topical_autologous_plasma_rich_membran?fr=sN2E3NDU1NzEyOTk'>
        The Role of Topical Autologous Plasma Rich Membranes in Healing Diabetic Foot Ulcers  <span className='article-item-divider'>|</span>  Prof Anand Pillai, Mr Noman Niazi
        </a>
        <br/>
        <br/>
        <a href='https://issuu.com/woundmasterclass/docs/how_to_approach_healing_the_diabetic_foot_-_the_sy/2?fr=sZmU2NTU1NzEyNzk'>
        How to Approach Healing the Diabetic Foot: The Synthetic Dermal Matrix Solution  <span className='article-item-divider'>|</span>  Mr Ross Robinson
        </a>
        <br/>
        <br/>
        </p>
    </div>
  </section>
  </>
  );
};

export default Articles;
