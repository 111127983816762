import '../styles/login.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { wmcApi } from '../utils/api';
import loggingin from '../images/loggingin.gif';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Login = ({
  setNotOptions,
  accessToken,
  setAccessToken,
  auth,
  setAuth,
  loginPage,
  setLoginPage,
  registerPage,
  setRegisterPage,
  subscribePage,
  setSubscribePage,
  setUserFirstName,
  loggedInMsg,
  setLoggedInMsg,
  mobile,
  userFirstName,
  userLastName,
  setUserLastName,
  userEmail,
  setUserEmail,
  userRole,
  setUserRole,
  userPostalCode,
  setUserPostalCode,
  secureLogin,
  setSecureLogin,
  secureSession,
  setSecureSession,
  setPodcastLogo
}) => {
  const navigate = useNavigate();
  const [loginAction, setLoginAction] = useState(false);
  const [loginDetails, setLoginDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [getFirstName, setGetFirstName] = useState(false);
  const [detailsMessage, setDetailsMessage] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [resetMessage, setResetMessage] = useState(false);
  const [resetAction, setResetAction] = useState(false);
  const [resetEmailValue, setResetEmailValue] = useState('');

  const redirectLink = secureLogin ? '/Accounts' : '/Home';

  useEffect(() => {
    setLoginPage(true);
    setNotOptions(false);
    if (registerPage) {
      setRegisterPage(false);
    } else if (subscribePage) {
      setSubscribePage(false);
    }
  }, []);

  useEffect(() => {
    setPodcastLogo(true);
  }, []);

  useEffect(() => {
    if (getFirstName) {
      axios
        .post(
          'https://wmcapi.herokuapp.com/api/user',
          { email: loginDetails.email, password: loginDetails.password },
          {
            headers: {
              authorization: accessToken,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          setLoginAction(false);
          return response;
        })
        .then((response) => {
          const firstNameCap =
            response.data.user.first_name.charAt(0).toUpperCase() +
            response.data.user.first_name.slice(1);
          const lastName = response.data.user.last_name;
          const email = response.data.user.email;
          const role = response.data.user.role;
          const postalCode = response.data.user.postal_code;

          setUserFirstName(firstNameCap);
          setUserLastName(lastName);
          setUserEmail(email);
          setUserRole(role);
          setUserPostalCode(postalCode);
          setSecureLogin(false);
          Cookies.set('user', firstNameCap, { expires: 7 });

          navigate('/Home');
        });
    }
  }, [getFirstName]);

  useEffect(() => {
    if (loginAction) {
      wmcApi
        .post('/login', loginDetails)
        .then((response) => {
          setAccessToken(response.data.accessToken);
          setAuth(true);
          setLoginAction(false);
          setGetFirstName(true);
        })
        .catch((err) => {
          setLoginDetails({});
          setLoginAction(false);
          setLoading(false);
          setDetailsMessage(true);
        });
    }
  }, [loginAction]);

  useEffect(() => {
    if (resetAction) {
      wmcApi
        .post('/reset_password', resetEmailValue)
        .then((response) => {
          setResetAction(false);
        })
        .catch((err) => {
          setResetAction(false);
        });
    }
  }, [resetAction]);

  const [inputEmailValue, setInputEmailValue] = useState('');
  const [inputPasswordValue, setInputPasswordValue] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    setLoginDetails({
      email: inputEmailValue.toLowerCase().trim(),
      password: inputPasswordValue.trim(),
    });
    setInputEmailValue('');
    setInputPasswordValue('');
    setLoading(true);
    setTimeout(() => setLoginAction(true), 1000);
  };

  const changeHandlerEmail = (e) => {
    setInputEmailValue(e.target.value);
  };

  const changeHandlerResetEmail = (e) => {
    setResetEmailValue(e.target.value);
  };

  const changeHandlerPassword = (e) => {
    setInputPasswordValue(e.target.value);
  };

  const scrollToTop = () => {
    if (inputEmailValue && inputPasswordValue) {
      document.documentElement.scrollTop = 0;
    }
  };

  const titleStyle = mobile ? 'mobile-title' : 'login-title';

  useEffect(() => {
    if (resetAction) {
    axios.post('https://wmcapi.herokuapp.com/api/reset', {
        email: resetEmailValue
      })
    }
  }, [resetAction]);

  if (
    !auth &&
    !loading &&
    !detailsMessage &&
    !resetForm &&
    !resetMessage &&
    !secureLogin
  ) {
    return (
      <section className='login-container'>
        <div className={titleStyle}>LOG IN</div>
        <div className='login-box'>
          <form className='login-form' id='login' onSubmit={submitHandler}>
            <div>
              <input
                required
                id='login-form-top-input'
                type='text'
                className='login-input'
                placeholder='Email'
                spellCheck='false'
                maxLength='50'
                onChange={changeHandlerEmail}
                value={inputEmailValue}
              ></input>
            </div>
            <div>
              <input
                required
                type='password'
                className='login-input'
                placeholder='Password'
                maxLength='25'
                onChange={changeHandlerPassword}
                value={inputPasswordValue}
              ></input>
            </div>
          </form>
        </div>
        <button
          className='form-button'
          form='login'
          onClick={() => {
            scrollToTop();
          }}
        >
          CONTINUE
        </button>
        <p className='forgot-question'>
          <span
            onClick={() => {
              setResetForm(true);
            }}
          >
            Forgot password?
          </span>
        </p>
        <p className='register-question'>
          Don't have an account?{' '}
          <Link to='/Register'>
            <span
              className='email-links'
              onClick={() => {
                document.documentElement.scrollTop = 0;
              }}
            >
              Register.
            </span>
          </Link>
        </p>
      </section>
    );
  } else if (loading) {
    return (
      <div className='loggingin-container'>
        <img src={loggingin} className='loggingin'></img>
      </div>
    );
  } else if (auth && !secureLogin) {
    if (!loggedInMsg) {
      return <></>;
    } else if (loggedInMsg)
      return (
        <div className='error-message'>
          <div className='message-box'>
            <p>You are already logged in.</p>
          </div>
        </div>
      );
  } else if (detailsMessage) {
    setTimeout(() => setDetailsMessage(false), 1700);
    return (
      <div className='error-message'>
        <div className='message-box'>
          <p>There was a problem with your details.</p>
        </div>
      </div>
    );
  } else if (resetForm) {
    return (
      <>
        <section className='login-container'>
          <div className='error-message'>
            <div className='message-box'>
              <p>Please enter the email associated with your account.</p>
            </div>
          </div>
          <div className='login-box'>
            <form className='login-form' id='reset-password-form'>
              <input
                required
                type='text'
                className='login-input'
                id='reset-password-input'
                placeholder='Email'
                spellCheck='false'
                maxLength='50'
                onChange={changeHandlerResetEmail}
                value={resetEmailValue}
              ></input>
            </form>
          </div>
          <button
            className='form-button'
            form='reset-password-form'
            onClick={() => {
              setResetAction(true);
              setResetMessage(true);
              setResetForm(false);
              document.documentElement.scrollTop = 0;
            }}
          >
            CONTINUE
          </button>
          <p className='lorem-ipsum' id='go-back'>
            <br />
            Go back to{' '}
            <span
              className='email-links'
              onClick={() => {
                setResetForm(false);
                document.documentElement.scrollTop = 0;
              }}
            >
              log in.
            </span>
          </p>
        </section>
      </>
    );
  } else if (resetMessage) {
    return (
      <>
        <div className='error-message'>
          <div className='message-box'>
            <p>
              Please check your email inbox for instructions to reset your
              password.
            </p>
          </div>
        </div>
        <p className='lorem-ipsum' id='go-back'>
          <br />
          Go back to{' '}
          <span
            className='email-links'
            onClick={() => {
              setResetMessage(false);
              document.documentElement.scrollTop = 0;
            }}
          >
            log in.
          </span>
        </p>
      </>
    );
  } else if (secureLogin) {
    return (
      <section className='login-container'>
        <div className={titleStyle}>LOG IN</div>
        <div className='login-box'>
          <form className='login-form' id='login' onSubmit={submitHandler}>
            <div>
              <input
                required
                id='login-form-top-input'
                type='text'
                className='login-input'
                placeholder='Email'
                spellCheck='false'
                maxLength='50'
                onChange={changeHandlerEmail}
                value={inputEmailValue}
              ></input>
            </div>
            <div>
              <input
                required
                type='password'
                className='login-input'
                placeholder='Password'
                maxLength='25'
                onChange={changeHandlerPassword}
                value={inputPasswordValue}
              ></input>
            </div>
          </form>
        </div>
        <button
          className='form-button'
          form='login'
          onClick={() => {
            setSecureSession(true);
            scrollToTop();
          }}
        >
          CONTINUE
        </button>
        <p className='forgot-question'>
          <span
            onClick={() => {
              setResetForm(true);
            }}
          >
            Forgot password?
          </span>
        </p>
      </section>
    );
  }
};

export default Login;
