import '../styles/podcast.css';
import podcast_episode4 from '../images/podcast_poster_episode_4.webp';
import podcast_episode3 from '../images/podcast-poster-episode3.webp';
import podcast_episode2 from '../images/podcast-poster-episode2.webp';
import podcast_episode1 from '../images/podcast-poster-episode 1.webp';
import podcast_episode5 from '../images/podcast-poster-episode5.webp';
import podcast_episode6 from '../images/podcast-poster-episode6.webp';
import podcast_episode7 from '../images/podcast 7 poster.jpeg'
import podcast_episode8 from '../images/podcast-poster-episode8.webp'
import podcast_episode9 from '../images/podcast-poster-episode9.webp'
import podcast_episode10 from '../images/podcast-poster-episode10.webp'
import podcast_episode11 from '../images/podcast-poster-episode11.webp'
import podcast_episode12 from '../images/podcast-poster-episode12.webp'

import '../styles/video.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

const Podcast = ({ setNotOptions, mobile, podcastLogo, setPodcastLogo }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  useEffect(() => {
    setPodcastLogo(false);
  }, []);

  const [episodeOne, setEpisodeOne] = useState(false);
  const [episodeTwo, setEpisodeTwo] = useState(false);
  const [episodeThree, setEpisodeThree] = useState(false);
  const [episodeFour, setEpisodeFour] = useState(false);
  const [episodeFive, setEpisodeFive] = useState(false);
  const [episodeSix, setEpisodeSix] = useState(false);
  const [episodeSeven, setEpisodeSeven] = useState(false);
  const [episodeEight, setEpisodeEight] = useState(false);
  const [episodeNine, setEpisodeNine] = useState(false);
  const [episodeTen, setEpisodeTen] = useState(false);
  const [episodeEleven, setEpisodeEleven] = useState(false)
  const [episodeTwelve, setEpisodeTwelve] = useState(false)

  const titleStyle = mobile ? 'mobile-title' : 'events-title';
  const podcastMenu = mobile ? 'mobile-video-menu' : 'desktop-video-menu';

  if (!episodeOne && !episodeTwo && !episodeThree && !episodeFour && !episodeFive && !episodeSix && !episodeSeven && !episodeEight && !episodeNine && !episodeTen && !episodeEleven &&!episodeTwelve) {
    return (
      <section className={podcastMenu}>
        <p className='video-main-title'>
          Welcome to The Wound Masterclass Podcast!
        </p>
        <p className='video-one-title'>
          The
          <span
            style={{
              color: '#ec3b83',
              // marginRight: '.3rem',
              marginLeft: '.4rem',
            }}
          >
            Wound
          </span>{' '}
          Masterclass Podcast <br />
          <br />
          <p className='video-one-title'>
            <br />
            <br />
            Coming soon! Episode Thirteen
          </p>
          {/* <img
            src={podcast_episode6}
            className='vascular-slide'
            // onClick={() => {
            //   setEpisodeTwo(true);
            //   document.documentElement.scrollTop = 500;
            // }}
          ></img> */}
          <p>Episode Twelve: Decoding Wound Descriptions: Why the Words We Use Matter</p>
          <br />
          <br />
          <img
            src={podcast_episode12}
            className='vascular-slide'
            onClick={() => {
              setEpisodeTwelve(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <br />
          <br />
          <p>Episode Eleven: The Art of Wound Care: Blending Science and Compassion</p>
          <br />
          <br />
          <img
            src={podcast_episode11}
            className='vascular-slide'
            onClick={() => {
              setEpisodeEleven(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <br />
          <br />
          <p>Episode Ten: One Size Does Not Fit All: Personalized Wound Treatment</p>
          <br />
          <br />
          <img
            src={podcast_episode10}
            className='vascular-slide'
            onClick={() => {
              setEpisodeTen(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <br />
          <br />
          <p>Episode Nine: The Time Factor: Healing Venous Leg Ulcers with Compression</p>
          <br />
          <br />
          <img
            src={podcast_episode9}
            className='vascular-slide'
            onClick={() => {
              setEpisodeNine(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <br />
          <br />
          <p>Episode Eight: What Are the Key Medicolegal Issues for Pressure Injuries?</p>
          <br />
          <br />
          <img
            src={podcast_episode8}
            className='vascular-slide'
            onClick={() => {
              setEpisodeEight(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          
          <br />
          <br />
           <p>Episode Six: Dodging the Medicolegal Bullet in Your Wound Care Practice</p>
          <br />
          <br />
          <img
            src={podcast_episode6}
            className='vascular-slide'
            onClick={() => {
              setEpisodeSix(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <br />
          <br />
          <p>Episode Five: Wound Care in the Military Setting</p>
          <br />
          <br />
          <img
            src={podcast_episode5}
            className='vascular-slide'
            onClick={() => {
              setEpisodeFive(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <br />
          <br />
          <p>Episode Four: The Future of 3D Printing and Wound Care</p>
          <br />
          <br />
          <img
            src={podcast_episode4}
            className='vascular-slide'
            onClick={() => {
              setEpisodeFour(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <br />
          <br />
          Episode Three: Vascular Origin of Pressure Injuries and the
          Implications For Prevention
        </p>
        <img
          src={podcast_episode3}
          className='vascular-slide'
          onClick={() => {
            setEpisodeThree(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <br />
          <br />
          Episode Two: Incorporating Innovation into Clinical Practice
        </p>
        <img
          src={podcast_episode2}
          className='vascular-slide'
          onClick={() => {
            setEpisodeTwo(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
        <p className='video-one-title'>
          <br />
          <br />
          Episode One: Myths and Misconceptions in Wound Care
        </p>
        <img
          src={podcast_episode1}
          className='vascular-slide'
          onClick={() => {
            setEpisodeOne(true);
            document.documentElement.scrollTop = 500;
          }}
        ></img>
      </section>
    );
  } else if (episodeThree && !episodeFour && !episodeTwo && !episodeOne) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/hg1VcvAZshM'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setEpisodeThree(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (episodeTwo && !episodeOne && !episodeThree && !episodeFour) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/uUIhAd_WeLw'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setEpisodeTwo(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (episodeOne && !episodeTwo && !episodeThree && !episodeFour) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/srQtOdF3_VM'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setEpisodeOne(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (episodeFour && !episodeOne && !episodeTwo && !episodeThree) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/MQnueL8ujwE'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setEpisodeFour(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (
    episodeFive &&
    !episodeOne &&
    !episodeTwo &&
    !episodeThree &&
    !episodeFour
  ) {
    return (
      <section className='video-one-page-container'>
        <section className='video-one-container'>
          <ReactPlayer
            url='https://youtu.be/q6Z4hLNlLYQ'
            width='100w'
            playing='true'
            controls='true'
          />
        </section>
        <div
          className='go-back-button-background-video'
          onClick={() => {
            setEpisodeFive(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-video'>Go Back</span>
        </div>
      </section>
    );
  } else if (episodeSix &&
    !episodeOne &&
    !episodeTwo &&
    !episodeThree &&
    !episodeFour &&
    !episodeFive) {
      return (
        <section className='video-one-page-container'>
          <section className='video-one-container'>
            <ReactPlayer
              url='https://youtu.be/CSMB0RFcVr8'
              width='100w'
              playing='true'
              controls='true'
            />
          </section>
          <div
            className='go-back-button-background-video'
            onClick={() => {
              setEpisodeSix(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-video'>Go Back</span>
          </div>
        </section>
      );
    } else if (episodeSeven &&
      !episodeOne &&
      !episodeTwo &&
      !episodeThree &&
      !episodeFour &&
      !episodeFive &&
      !episodeSix) {
        return (
          <section className='video-one-page-container'>
            <section className='video-one-container'>
              <ReactPlayer
                url='https://youtu.be/ty9K9E0ig0A'
                width='100w'
                playing='true'
                controls='true'
              />
            </section>
            <div
              className='go-back-button-background-video'
              onClick={() => {
                setEpisodeSeven(false);
                document.documentElement.scrollTop = 500;
              }}
            >
              <span className='go-back-button-video'>Go Back</span>
            </div>
          </section>
        );
      } else if (
        episodeEight &&
        !episodeSeven &&
        !episodeOne &&
        !episodeTwo &&
        !episodeThree &&
        !episodeFour &&
        !episodeFive &&
        !episodeSix) {
          return (
            <section className='video-one-page-container'>
              <section className='video-one-container'>
                <ReactPlayer
                  url='https://youtu.be/tzGmQF3qAQ8'
                  width='100w'
                  playing='true'
                  controls='true'
                />
              </section>
              <div
                className='go-back-button-background-video'
                onClick={() => {
                  setEpisodeEight(false);
                  document.documentElement.scrollTop = 500;
                }}
              >
                <span className='go-back-button-video'>Go Back</span>
              </div>
            </section>
          );
              }
              else if (
                episodeNine &&
                !episodeEight &&
                !episodeSeven &&
                !episodeOne &&
                !episodeTwo &&
                !episodeThree &&
                !episodeFour &&
                !episodeFive &&
                !episodeSix) {
                  return (
                    <section className='video-one-page-container'>
                      <section className='video-one-container'>
                        <ReactPlayer
                          url='https://youtu.be/Qx11MxbJfrY'
                          width='100w'
                          playing='true'
                          controls='true'
                        />
                      </section>
                      <div
                        className='go-back-button-background-video'
                        onClick={() => {
                          setEpisodeNine(false);
                          document.documentElement.scrollTop = 500;
                        }}
                      >
                        <span className='go-back-button-video'>Go Back</span>
                      </div>
                    </section>
                  );
                      } else if (
                        episodeTen &&
                        !episodeNine &&
                        !episodeEight &&
                        !episodeSeven &&
                        !episodeOne &&
                        !episodeTwo &&
                        !episodeThree &&
                        !episodeFour &&
                        !episodeFive &&
                        !episodeSix) {
                          return (
                            <section className='video-one-page-container'>
                              <section className='video-one-container'>
                                <ReactPlayer
                                  url='https://youtu.be/97O3sjnHxjE'
                                  width='100w'
                                  playing='true'
                                  controls='true'
                                />
                              </section>
                              <div
                                className='go-back-button-background-video'
                                onClick={() => {
                                  setEpisodeTen(false);
                                  document.documentElement.scrollTop = 500;
                                }}
                              >
                                <span className='go-back-button-video'>Go Back</span>
                              </div>
                            </section>
                          );
                              } else if (
                                episodeEleven &&
                                !episodeTen &&
                                !episodeNine &&
                                !episodeEight &&
                                !episodeSeven &&
                                !episodeOne &&
                                !episodeTwo &&
                                !episodeThree &&
                                !episodeFour &&
                                !episodeFive &&
                                !episodeSix) {
                                  return (
                                    <section className='video-one-page-container'>
                                      <section className='video-one-container'>
                                        <ReactPlayer
                                          url='https://youtu.be/BmYKREC6WS8'
                                          width='100w'
                                          playing='true'
                                          controls='true'
                                        />
                                      </section>
                                      <div
                                        className='go-back-button-background-video'
                                        onClick={() => {
                                          setEpisodeEleven(false);
                                          document.documentElement.scrollTop = 500;
                                        }}
                                      >
                                        <span className='go-back-button-video'>Go Back</span>
                                      </div>
                                    </section>
                                  );
                                      } else if (
                                        episodeTwelve &&
                                        !episodeEleven &&
                                        !episodeTen &&
                                        !episodeNine &&
                                        !episodeEight &&
                                        !episodeSeven &&
                                        !episodeOne &&
                                        !episodeTwo &&
                                        !episodeThree &&
                                        !episodeFour &&
                                        !episodeFive &&
                                        !episodeSix) {
                                          return (
                                            <section className='video-one-page-container'>
                                              <section className='video-one-container'>
                                                <ReactPlayer
                                                  url='https://youtu.be/LGJo76H1XQ8'
                                                  width='100w'
                                                  playing='true'
                                                  controls='true'
                                                />
                                              </section>
                                              <div
                                                className='go-back-button-background-video'
                                                onClick={() => {
                                                  setEpisodeTwelve(false);
                                                  document.documentElement.scrollTop = 500;
                                                }}
                                              >
                                                <span className='go-back-button-video'>Go Back</span>
                                              </div>
                                            </section>
                                          );
                                              }
};

export default Podcast;
