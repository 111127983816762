import '../styles/privacy-policy.css';
import { useEffect } from 'react';

const EditorialPolicy = ({ setNotOptions, setPodcastLogo }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);
  useEffect(() => {
    setPodcastLogo(true);
  }, []);
  return (
    <>
      <h1 className='privacy-policy-title'>EDITORIAL POLICY</h1>
      <div className='page-wrapper'>
        <section className='privacy-policy-container'>
          <br />
          <br />
          <br />
          <h1
            style={{
              margin: '0px 0px 16px',
              fontSize: '40px',
              lineHeight: '60px',
              fontWeight: 700,
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Editorial Policy
          </h1>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Last updated: January 31, 2024
          </p>

          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Scope and Mission
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Wound Masterclass publishes original research articles, systematic
            reviews, meta-analyses, and clinical guidelines in all fields of
            wound care. Wound Masterclass provides free, peer-reviewed, indexed
            content. The aim is to promote the scientific quality of medicine
            and improve patient care through the rapid dissemination of medical
            knowledge globally without a subscription fee or a pay per article
            system.
          </p>
          <br />
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Editorial Leadership
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            The Editor-in-Chief with the editorial board holds final
            responsibility for the journal’s content and editorial direction.
            The journal maintains an expert Editorial Board covering all areas
            of wound care. Specialists peer review submissions and provide
            recommendations on publication decisions.
          </p>
          <br />
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Author Guidelines
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Original research articles should contain a structured format , key
            words, introduction/background, methods, results, discussion and
            references. Systematic reviews and meta-analyses must be prepared
            according to PRISMA guidelines. Clinical guidelines should be
            evidence-based. All studies on human subjects must adhere to
            Declaration of Helsinki standards.
          </p>
          <br />
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Peer Review Process
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            All manuscripts undergo a rigorous, fair and timely double-blinded
            peer review by at least two specialists. Reviewers evaluate the
            importance, originality, study methodology, analysis, interpretation
            of data, language, adherence to author guidelines and ethical
            standards.
          </p>
          <br />
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Conflicts of Interest
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Authors must disclose all financial, commercial or personal
            relationships that could influence their submitted work. Referees
            with a conflict of interest will be excluded from the peer review
            process. Sources of funding are published.
          </p>
          <br />
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Research Ethics
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Any report of unethical research or unreliable data that falls below
            ethical standards may prompt investigation and retraction. Authors
            must obtain patient consent and research ethics board approval where
            required. Trials should be registered in recognized trial
            registries.
          </p>
          <br />
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Copyright and Access
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Wound Masterclass retains copyright and issues global digital
            licenses when requested, content is made freely accessible online
            immediately upon publication under an open access.
          </p>
          <br />
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Corrections and Retractions
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Published articles found to contain errors may warrant corrections
            which are clearly linked to the original. In cases of unreliable
            data, plagiarism or unethical research, formal retraction with an
            explanation may follow.
            <br />
            <br />
            Our editorial policy follows COPE and ICMJE international standards
            for medical journal best practices. We aim for excellence,
            transparency and integrity in medical publishing.
          </p>
          <br/>
          <h2
            style={{
              margin: '0px 0px 16px',
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '48px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              wordWrap: 'break-word',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Clinical Photographs
          </h2>
          <p
            style={{
              margin: '0px 0px 16px',
              color: 'rgb(0, 0, 0)',
              fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontVariantLigatures: 'normal',
              fontVariantCaps: 'normal',
              fontWeight: 400,
              letterSpacing: 'normal',
              orphans: 2,
              textAlign: 'start',
              textIndent: '0px',
              textTransform: 'none',
              whiteSpace: 'normal',
              widows: 2,
              wordSpacing: '0px',
              WebkitTextStrokeWidth: '0px',
              backgroundColor: 'rgb(255, 255, 255)',
              textDecorationThickness: 'initial',
              textDecorationStyle: 'initial',
              textDecorationColor: 'initial',
            }}
          >
            Any and all clinical photographs are published with the consent of the author of the respective article.
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </section>
      </div>
    </>
  );
};

export default EditorialPolicy;
