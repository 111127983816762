import '../styles/events.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import vascular_slide from '../images/vascular-slide.webp';
import ssi_slide from '../images/ssi-slide.webp';
import oxygen_slide from '../images/oxygen-slide.webp';
import pressure_slide from '../images/pressure-slide.webp';
import masterseries_slide0 from '../images/space-slide.webp';
import masterseries_slide1 from '../images/masterseries slide 1.webp';
import masterseries_slide2 from '../images/masterseries-london.webp';
import masterseries_slide3 from '../images/masterseries-newyork.webp';
import masterseries_slide4 from '../images/masterseries-seoul.webp';
import vascular_speakers from '../images/vascular-speakers.png';
import accellogo from '../images/accellogo.webp';
import alirahealthlogo from '../images/alirahealthlogo.webp';
import aotilogo from '../images/aoti-logo.webp';
import omnistatlogo from '../images/omni-stat-logo.webp';
import kerecislogo from '../images/kerecis-logo.webp';
import sanuwavelogo from '../images/sanuwave-logo.webp';
import medicalfxlogo from '../images/medicalfx-logo.webp';
import essitylogo from '../images/essity.webp';
import histologicslogo from '../images/histologics.webp';
import polynovologo from '../images/polynovo.webp';
import podcast_episode from '../images/podcast-poster-episode5.webp';
import podcast_episode2 from '../images/podcast-poster-episode6.webp';
import auditorium_vascular from '../images/auditorium-summit.webp';
import live_now from '../images/live-now.gif';
import melin_gif from '../images/melin-gif.gif';
import alira_booth from '../images/alira-booth.webp';
import aoti_booth from '../images/aoti-booth.webp';
import accel_booth from '../images/accel-booth.webp';
import kerecis_booth from '../images/kerecis-booth.webp';
import sanuwave_booth from '../images/sanuwave-booth.webp';
import medicalfx_booth from '../images/medicalfx-booth.webp';
import omnistat_booth from '../images/omni-stat-booth.webp';
import essity_booth from '../images/essitybooth.webp';
import histologics_booth from '../images/histologicsbooth.webp';
import prodevlabs_booth from '../images/prodevlabsbooth.webp';
import polynovo_booth from '../images/polynovobooth.webp';
import summit_speakers from '../images/palliative speakers.webp';
import summit_poster from '../images/summit-poster.webp';
import ewma1 from '../images/ewma1.webp';
import palliative_speakers from '../images/palliative-poster-speakers.webp';
import hartmannlogo from '../images/logo-hartmann.webp';
import hartmannbooth from '../images/hartmann booth.webp';
import sssummit from '../images/skin-substitutes-poster-big.webp'
import wound_bed_prep_poster from '../images/wound-bed-prep-poster.webp'
import bioactive_glass_poster from '../images/bioactive-glass-poster-ms.webp'
import may_6_event from '../images/MasterSeries May 8 V7.webp'
import stoma1 from '../images/stoma 1.webp'
import stoma2 from '../images/stoma 2.webp'
import stoma3 from '../images/stoma 3.webp'

const Events = ({ setNotOptions, mobile, setPodcastLogo }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);
  useEffect(() => {
    setPodcastLogo(true);
  }, []);

  const [auditorium, setAuditorium] = useState(false);
  const [auditoriumLive, setAuditoriumLive] = useState(false);
  const [sponsorBooths, setSponsorBooths] = useState(false);
  const [sponsorOne, setSponsorOne] = useState(false);
  const [sponsorTwo, setSponsorTwo] = useState(false);
  const [sponsorThree, setSponsorThree] = useState(false);
  const [sponsorFour, setSponsorFour] = useState(false);
  const [sponsorFive, setSponsorFive] = useState(false);
  const [sponsorSix, setSponsorSix] = useState(false);
  const [sponsorSeven, setSponsorSeven] = useState(false);
  const [sponsorEight, setSponsorEight] = useState(false);
  const [sponsorNine, setSponsorNine] = useState(false);
  const [sponsorTen, setSponsorTen] = useState(false);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const titleStyle = mobile ? 'mobile-title' : 'events-title';
  const eventsContainer = mobile
    ? 'events-container'
    : 'events-container-desktop';

  if (
    !auditorium &&
    !sponsorBooths &&
    !sponsorOne &&
    !sponsorTwo &&
    !sponsorThree &&
    !sponsorFive &&
    !sponsorSix &&
    !sponsorSeven &&
    !sponsorFour &&
    !sponsorSeven &&
    !sponsorEight &&
    !sponsorNine &&
    !sponsorTen
  ) {
    return (
      <section className={eventsContainer}>
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
        >
          <a href='https://woundmasterclass.com/bioactive-matrix' target='_blank'> 
          <div>
            <img src={may_6_event} />
          </div>
          </a>
          <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
          <div>
            <img src={stoma1} />
          </div>
          </a>
          <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
          <div>
            <img src={stoma2} />
          </div>
          </a>
          <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
          <div>
            <img src={stoma3} />
          </div>
          </a>
          <a href='https://woundmasterclass.com/stoma-masterclass' target='_blank'> 
          <div>
            <img src={bioactive_glass_poster} />
          </div>
          </a>
          <div><img src={wound_bed_prep_poster} /></div>
          <div>
            <img src={sssummit} />
          </div>
          <div>
            <img src={masterseries_slide0} />
          </div>
          <div>
            <img src={bioactive_glass_poster} />
          </div>
          <div>
            <img src={palliative_speakers} />
          </div>
          <div>
            <img src={summit_poster} />
          </div>
          
          <div>
            <img src={vascular_slide} />
          </div>

          <div>
            <img src={ssi_slide} />
          </div>
          <div>
            <img src={oxygen_slide} />
          </div>
          <div>
            <img src={pressure_slide} />
          </div>
          <div>
            <img src={podcast_episode} />
          </div>
          <div>
            <img src={podcast_episode2} />
          </div>
          <div>
            <img src={masterseries_slide1} />
          </div>
          <div>
            <img src={masterseries_slide2} />
          </div>
          <div>
            <img src={masterseries_slide3} />
          </div>
          <div>
            <img src={masterseries_slide4} />
          </div>
        </Carousel>
        <div className='first-banner'>
          <p>
            <span className='masterseries' style={{ marginRight: '.3rem' }}>
              MasterSeries
            </span>{' '}
            60 Minutes Interactive
            {/* Global Innovation in Wound Care Summit */}
          </p>
        </div>
        <div className='intro-banner'>
          <p>
            Wound Masterclass welcomes you to our MasterSeries Interactive 60
            Minutes virtual events, featuring presentations from global experts
            in wound care. During the stream we invite you to ask any questions
            that come to mind, and join us towards the end of the event for an
            interactive live Q&A session with our panel of experts, where your
            questions will be answered.
          </p>
        </div>
        <div className='next-event-section'>
          <span>
          Destination Closure: Strategies for Wound Closure Exploring Bioactive Glass Wound Care
          </span>
        </div>

        <div className='countdown'>
          <div>
            <Countdown
              id='cd'
              date={'2024-05-08T18:00:00'}
              intervalDelay={0}
              precision={3}
              // zeroPadDays={1}
              // zeroPadTime={1}
              // renderer={(props) => <div>{props.total}</div>}
            />
          </div>
        </div>
        <div className='next-event-title'>
          <p>
            All your questions answered
            <br />
            <br />
            <br />
            <div className='auditorium-sponsor-booths'>
              <div
                id='auditorium-link'
                onClick={() => {
                  setAuditorium(true);
                  document.documentElement.scrollTop = 500;
                }}
              >
                Auditorium
              </div>
              {/* <div
                id='sponsor-booth-link'
                onClick={() => {
                  setSponsorBooths(true);
                  document.documentElement.scrollTop = 500;
                }}
              >
                Sponsor Booth
              </div> */}
            </div>
            <span className='next-event-date'>May 8th 2024</span>
          </p>
        </div>

        {/* <div className='next-event-section'>
          <span>SPEAKERS</span>
        </div>

        <div className='vascular-speakers'>
          <img src={summit_speakers}></img>
        </div> */}
        {/* <span className='agenda-title'>Agenda</span>
        <div className='agenda-list-container'>
          <span>Advances in Dressings</span>
          <span>Wound Bed Prep and Debridement </span>
          <span>Surgical Site Infection</span>
          <span>Venous Leg Ulcer (VLU)</span>
          <span>Diabetic Foot Ulcer (DFU)</span>
          <span>Pressure Injuries</span>
          <span>Lymphedema </span>
          <span>Obstetric Wounds</span>
          <span>Biofilm</span>
          <span>Oxygen Therapy</span>
          <span>Skin Substitutes</span>
          <span>Three Dimensional Printing</span>
          <span>Advanced Modalities</span>
        </div> */}
        <a
          href='https://woundmasterclass.com/dressing-technology.html'
          className='register-here'
          onClick={() => scrollToTop()}
        >
          <span id='register-here-text'>Register Here</span>
        </a>
        {/* <span className='sponsored-by'>Supported by:</span>
        <div id='sponsors'>
          <div className='logos'> */}
            {/* <a href='https://www.hartmann.info/'>
              {' '}
              <img src={hartmannlogo} className='logo-image'></img>
            </a> */}
            {/* <a href='https://aotinc.net/'>
              <img src={aotilogo} className='logo-image'></img>
            </a>
            <a href='https://www.accelheal.com/'>
              <img src={accellogo} className='logo-image'></img>
            </a>
            <a href='https://www.omni-stat.com/'>
              <img src={omnistatlogo} className='logo-image'></img>
            </a>
            <a href='https://www.kerecis.com/'>
              <img src={kerecislogo} className='logo-image'></img>
            </a>
            <a href='https://www.sanuwave.com/'>
              <img src={sanuwavelogo} className='logo-image'></img>
            </a>
            <a href='https://www.medicalfx.de/'>
              <img src={medicalfxlogo} className='logo-image'></img>
            </a>
            <a href='https://www.essity.com/'>
              <img src={essitylogo} className='logo-image'></img>
            </a>
            <a href='https://www.histologics.com/'>
              <img src={histologicslogo} className='logo-image'></img>
            </a>
            <a href='https://www.polynovo.com/'>
              <img src={polynovologo} className='logo-image'></img>
            </a>
            <a href='https://www.prodevlabs.com/'>
              <img src={alirahealthlogo} className='logo-image'></img>
            </a>
          </div>
        </div> */}
      </section>
    );
  } else if (
    auditoriumLive &&
    auditorium &&
    !sponsorOne &&
    !sponsorTwo &&
    !sponsorThree &&
    !sponsorFour &&
    !sponsorFive &&
    !sponsorSix &&
    !sponsorSeven &&
    !sponsorBooths &&
    !sponsorEight &&
    !sponsorNine &&
    !sponsorTen
  ) {
    return (
      <section className='auditorium-container'>
        <a href='https://www.bigmarker.com/wound-masterclass/MasterSeries-60-Minutes-Interactive-Getting-the-Best-Patient-Outcomes-in-Chronic-Venous-Disease-From-Micro-to-Macro'>
          <div className='auditorium-image-and-text'>
            <img
              src={auditorium_vascular}
              className='auditorium-vascular'
            ></img>
            <img src={melin_gif} className='melin-gif' />
            <img src={live_now} className='live-now' />
          </div>
        </a>
      </section>
    );
  } else if (
    !auditoriumLive &&
    auditorium &&
    !sponsorOne &&
    !sponsorTwo &&
    !sponsorThree &&
    !sponsorFour &&
    !sponsorFive &&
    !sponsorSix &&
    !sponsorSeven &&
    !sponsorBooths &&
    !sponsorEight &&
    !sponsorNine &&
    !sponsorTen
  ) {
    return (
      <section className='auditorium-container'>
        <div className='auditorium-image-and-text'>
          <img src={auditorium_vascular} className='auditorium-vascular'></img>

          <div className='early'>
            <span className='early-span'>You're early!</span>
            <br />
            <br />

            <br />
            <br />
            {/* 1PM EST <span className='date-line'>|</span> 6PM GMT */}
            <br />
            <br />
            <div
              className='go-back-button-background'
              onClick={() => {
                setAuditorium(false);
                document.documentElement.scrollTop = 500;
              }}
            >
              <span className='go-back-button'>Go Back</span>
            </div>
          </div>
        </div>
      </section>
    );
  } else if (
    !auditorium &&
    !sponsorOne &&
    !sponsorTwo &&
    !sponsorThree &&
    !sponsorFour &&
    !sponsorFive &&
    !sponsorSix &&
    !sponsorSeven &&
    sponsorBooths &&
    !sponsorEight &&
    !sponsorNine &&
    !sponsorTen
  ) {
    return (
      <section className='sponsor-choice-container'>
        <div className='choose-sponsor-message'>
          MasterSeries 60 Minutes Interactive: Clinical Challenges and Solutions
          in Palliative Wound Management is supported by Advanced Oxygen Therapy Inc., Accel-Heal, OMNI-STAT, Kerecis, SANUWAVE, MEDICAL FX, Histologics, PolyNovo, and Pro Dev Labs.
          <br />
          <br />
          Please select sponsor to view booth.
        </div>
        <div className='logos'>
          {' '}
          {/* <img
            src={hartmannlogo}
            className='logo-image'
            onClick={() => {
              setSponsorOne(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img> */}
          <img
            src={aotilogo}
            className='logo-image'
            onClick={() => {
              setSponsorTwo(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={accellogo}
            className='logo-image'
            onClick={() => {
              setSponsorThree(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={omnistatlogo}
            className='logo-image'
            onClick={() => {
              setSponsorFour(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={kerecislogo}
            className='logo-image'
            onClick={() => {
              setSponsorFive(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={sanuwavelogo}
            className='logo-image'
            onClick={() => {
              setSponsorSix(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={medicalfxlogo}
            className='logo-image'
            onClick={() => {
              setSponsorSeven(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={essitylogo}
            className='logo-image'
            onClick={() => {
              setSponsorEight(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={histologicslogo}
            className='logo-image'
            onClick={() => {
              setSponsorNine(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={polynovologo}
            className='logo-image'
            onClick={() => {
              setSponsorTen(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
          <img
            src={alirahealthlogo}
            className='logo-image'
            onClick={() => {
              setSponsorTen(true);
              document.documentElement.scrollTop = 500;
            }}
          ></img>
        </div>
        <div
          className='go-back-button-sponsor-choice-background'
          onClick={() => {
            setSponsorBooths(false);
            document.documentElement.scrollTop = 500;
          }}
        >
          <span className='go-back-button-sponsor-choice'>Go Back</span>
        </div>
      </section>
    );
  } else if (
    sponsorOne &&
    !sponsorTwo &&
    !auditorium &&
    !sponsorThree &&
    !sponsorFour &&
    !sponsorFive &&
    !sponsorSix &&
    !sponsorSeven &&
    !sponsorEight &&
    !sponsorNine &&
    !sponsorTen
  ) {
    return (
      <section className='sponsor-one-container'>
        <img src={hartmannbooth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>About HARTMANN</span>{' '}
          <a href='https://hartmann.info/'>
            <span className='booth-sponsor-links'>| hartmann.info</span>
          </a>
          <br />
          <br />
          We help, care, protect in more than 30 countries, but our solutions
          for wound care, incontinence, disinfection, and surgical efficiency
          are available in over 100 countries through a network of distributors.
          Wherever you see the HARTMANN brand and blue oval, you can count on
          professional partnership, quality, expertise, and a passion for always
          improving healthcare.
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorOne(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorTwo && !sponsorOne && !sponsorThree && !sponsorFour) {
    return (
      <section className='sponsor-one-container'>
        <img src={aoti_booth} className='sponsor-one-booth-image'></img>
        <div className='urgo-booth-text'>
          <span style={{ fontWeight: 'bold' }}>
            AOTI (Advanced Oxygen Therapy Inc.)
          </span>{' '}
          <a href='https://aotinc.net/'>
            <span className='booth-sponsor-links'>| aotinc.net</span>
          </a>
          <br />
          <br /> At AOTI, we are helping all people with chronic conditions get
          back to living their lives to the fullest. We do this by enhancing
          access to care, improving clinical outcomes and quality of life, and
          advancing health equity. <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>
            AOTI is recognized as a leader in wound care technology around the
            world.
          </span>{' '}
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorTwo(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorThree) {
    return (
      <section className='sponsor-one-container'>
        <img src={accel_booth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>
            About Accel-Heal Technologies Limited
          </span>{' '}
          <a href='https://www.accelheal.com/'>
            <span className='booth-sponsor-links'>| accelheal.com</span>
          </a>
          <br />
          <br /> Accel-Heal Technologies Limited is a UK-based company that was
          established to continue the development of unique electrical
          stimulation products to help transform clinical and health economic
          outcomes in patients with painful and hard-to-heal wounds. <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>TRANSFORM PATIENT OUTCOMES</span>
          <br />
          <br />
          Electrical stimulation has a real potential to transform outcomes in
          patients suffering from painful, hard-to-heal wounds. It is now one of
          the most evidence-based therapies in wound management having shown its
          efficacy to stimulate healing and help to relieve pain in numerous
          clinical trials. The challenge has always been how to incorporate what
          has been perceived as a complex therapy, into frontline clinical
          practice. Accel‑Heal, as a pre-programmed, single-use, portable and
          easy to use electrical stimulation device, has been developed to
          address this challenge.
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorThree(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorFour) {
    return (
      <section className='sponsor-one-container'>
        <img src={omnistat_booth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>OMNI-STAT</span>{' '}
          <a href='https://www.omni-stat.com/'>
            <span className='booth-sponsor-links'>| omni-stat.com</span>
          </a>
          <br />
          <br />{' '}
          <span style={{ fontWeight: 'bold' }}>
            About Omni-stat Medical Inc
          </span>
          <br />
          <br /> OMNI-STAT® and CELOXTM Hemostatic agents are marketed by
          Omni-stat Medical Inc, which has recently been established in 2018
          with a small direct team of experts. <br />
          <br />
          OMNI-STAT and CELOX are brand names of equivalent proprietary chitosan
          technology.
          <br />
          <br /> CELOX has a long history of use in the military since 2006 and
          has been added to the military’s Committee on Tactical Combat Casualty
          Care’s (CoTCCC) guidelines for control of hemorrhage as an approved
          hemostatic agent for military-wide use.*
          <br />
          <br /> Omni-stat Medical Inc is proud to offer our 510k cleared
          hemostats with unique chitosan technology to hospitals throughout the
          United States.
          <br />
          <br /> *For Military & Civilian use of CELOX Products please visit{' '}
          <a href='https://www.celoxmedical.com/'>
            <span
              className='booth-sponsor-links'
              style={{ fontWeight: 'bold' }}
            >
              www.celoxmedical.com
            </span>
          </a>
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorFour(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorFive) {
    return (
      <section className='sponsor-one-container'>
        <img src={kerecis_booth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>Kerecis</span>{' '}
          <a href='https://www.kerecis.com/'>
            <span className='booth-sponsor-links'>| kerecis.com</span>
          </a>
          <br />
          <br /> <span style={{ fontWeight: 'bold' }}>About Kerecis</span>
          <br />
          <br /> Kerecis is intact fish skin rich in naturally occurring Omega3
          polyunsaturated fatty acids. When grafted onto damaged human tissue
          such as a burn or a diabetic wound, the material recruits the body’s
          own cells and is ultimately converted into living tissue. <br />
          <br />
          Compared to mammalian-based skin substitutes, Kerecis offers improved
          economics and clinical performance, as well as reduced disease
          transfer risk and no cultural constraints on usage.
          <br />
          <br /> Other tissue-transplant products are based on tissues of human
          and porcine origin. These are not ideal substitutes because heavy
          processing is needed to eliminate the risk of disease transmission.
          This harsh, anti-viral treatment removes most of the material’s
          natural components, making it dissimilar to human skin.
          <br />
          <br /> Fish skin has been shown to be more similar in structure to
          human skin than anti-viral–processed skin substitutes. Because there
          is no risk of disease transmission, fish skin is only minimally
          processed, preserving its structure and components. In a double-blind,
          comparative, randomized controlled clinical trial (N=162), fish skin
          favorably compared with mammalian skin substitutes.
          <br />
          <br />
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorFive(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorSix) {
    return (
      <section className='sponsor-one-container'>
        <img src={sanuwave_booth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>SANUWAVE</span>{' '}
          <a href='https://www.sanuwave.com/'>
            <span className='booth-sponsor-links'>| sanuwave.com</span>
          </a>
          <br />
          <br /> <span style={{ fontWeight: 'bold' }}>About SANUWAVE</span>
          <br />
          <br /> As an emerging leader in the development and commercialization
          of energy transfer technology, SANUWAVE® presents products that help
          expedite wound healing on a cellular level. SANUWAVE’s easy-to-use
          ENERGY FIRST® product portfolio works to help heal some of the most
          difficult, stalled, or hard-to-heal wounds by activating the body’s
          normal regenerative process using an assortment of energy waves, from
          shockwaves to ultrasound. <br />
          <br />
          SANUWAVE values patient comfort and the importance of wound healing,
          recognizing its’ value to not only improve patient quality of life but
          to help avoid further complications that could lead to amputation or
          even lives lost. SANUWAVE’s minimally to completely non-invasive
          technologies can help wounds heal with little to no pain or
          discomfort. Most treatments can be done in under ten minutes, however,
          results may vary depending on wound size and patient response to
          treatment.
          <br />
          <br />
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorSix(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorSeven) {
    return (
      <section className='sponsor-one-container'>
        <img src={medicalfx_booth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>MEDICAL FX</span>{' '}
          <a href='https://www.medicalfx.de/'>
            <span className='booth-sponsor-links'>| medicalfx.de</span>
          </a>
          <br />
          <br /> <span style={{ fontWeight: 'bold' }}>About MEDICAL FX</span>
          <br />
          <br /> Our anatomical models not only look highly realistic, they also
          feel that way. We use very high-quality materials for production.{' '}
          <br />
          <br />
          We set ourselves apart from our competition by manufacturing
          everything 100% by hand in our factory in Germany.
          <br />
          <br /> We do not use any brands from distant countries. So we can
          guarantee absolutely sustainable products. You get this guarantee from
          me - because I ensure the satisfaction of my customers with a
          five-year warranty. So if there are any defects during this time, we
          will exchange our products for you - uncomplicated and fair.
          <br />
          <br /> We are specialized in custom made products. This is also an
          advantage for you. If you need a suitable demonstration model for your
          application, we can make it for you - according to your wishes.
          Organic illustrations, cross-sections or silicone wounds: We make it.
          That is the reason why we successfully work with major customers like
          Paul Hartmann AG, Lohmann&Rauscher, Smith&Nephew or BBraun – as
          long-standing, reliable and creative partners
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorSeven(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorEight) {
    return (
      <section className='sponsor-one-container'>
        <img src={essity_booth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>Essity</span>{' '}
          <a href='https://www.essity.com/'>
            <span className='booth-sponsor-links'>| essity.com</span>
          </a>
          <br />
          <br /> <span style={{ fontWeight: 'bold' }}>About Essity</span>
          <br />
          <br /> Essity is a leading global hygiene and health company,
          dedicated to improving well-being through excellent products and
          services. As part of its personal care offering, Essity provides
          innovative, high-quality medical solutions in compression, wound care
          and orthopedics. With renowned brands such as Leukoplast, Cutimed,
          JOBST, Actimove and Delta-Cast.
          <br />
          <br />
          With a comprehensive portfolio of products, solutions and services,
          Essity is one of the four big global players in its categories. The
          focus is on serving the needs of customers, which include retail
          trade, online sales, pharmacies, hospitals, distributors, care
          institutions and consumers.
          <br />
          <br />
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorEight(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorNine) {
    return (
      <section className='sponsor-one-container'>
        <img src={histologics_booth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>Histologics</span>{' '}
          <a href='https://www.histologics.com/'>
            <span className='booth-sponsor-links'>| histologics.com</span>
          </a>
          <br />
          <br /> <span style={{ fontWeight: 'bold' }}>About Histologics</span>
          <br />
          <br /> Histologics LLC’s primary objective is to advance the field of
          tissue sampling into the 21st century. Proper tissue sampling and care
          during colposcopic practice and other medical settings should serve to
          save lives, worldwide.
          <br />
          <br />
          Histologics LLC was established in 2008 out of a dedication to women
          undergoing evaluation for abnormal cervical cancer screening and a
          need for better histologic sampling. The founders, including Ob/Gyn
          inventor Dr. Neal Lonky, developed Speculoscopy (PapSure ®) and
          Spirabrush CX® in the 1980's and early 1990's (respectively). Years of
          dedication to improving women's healthcare led the company to innovate
          a different approach to obtain tissue samples from the cervix, lower
          genital tract, and other epithelium. Histologics developed a medical
          fabric called KYLON®, comprised of fenestrated loops that create
          unique shaped hooks woven into nylon fabric. When applied to tissue
          with pressure, the hooks flex downward exposing the hook tip to the
          tissue face when rotated or agitated on the epithelial surface.
          Histological samples are both removed and stored inside the fabric,
          unlike conventional metal tip punch biopsy or curette devices where
          the clinician removes the sample from the tip and places the tissue
          into a vial of fixative.. Therefore, the fabric is a "system"
          comprising a tissue collection function resultng in: 1. a
          tran-epithelial biopsy sample, and, 2. a container that traps the
          dislodged tissue, which is stored on the device tip. The tissue-filled
          device tip is snapped free from the handle to be transported to the
          lab. There is no need for the colposcopist clinician to handle the
          specimen or clean biopsy devices, reducing the risk of contamination
          during the colposcopy procedure and assuring the lab technicians
          recieve the entire collected sample for the Pathologist's analysis
          after processing.
          <br />
          <br />
          Unlike bristle brushes, fabric can literally be applied to any device
          contour, shape, or surface to match the tissue surface sampled.
          Gynecological biopsy devices are registered with the FDA, and are
          after research validation, are dually listed as Gynecological biopsy
          devices, curettes, and systems which are containers for specimen
          storage and trasport as “Class 1, exempt from 510k registration".
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorNine(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  } else if (sponsorTen) {
    return (
      <section className='sponsor-one-container'>
        <img src={polynovo_booth} className='sponsor-one-booth-image'></img>
        <div className='aroa-booth-text'>
          <span style={{ fontWeight: 'bold' }}>PolyNovo</span>{' '}
          <a href='https://www.polynovo.com/'>
            <span className='booth-sponsor-links'>| polynovo.com</span>
          </a>
          <br />
          <br /> <span style={{ fontWeight: 'bold' }}>About PolyNovo</span>
          <br />
          <br /> At PolyNovo we are driven by improving the outcomes of patients
          through development and provision of the best surgical solutions
          possible.
          <br />
          <br />
          PolyNovo develops innovative medical devices utilizing the patented
          bioabsorbable polymer technology Novosorb®.
          <br />
          <br />
          NovoSorb is a family of proprietary medical grade polymers that can be
          expressed in a variety of physical formats. NovoSorb polymers have
          advantageous properties such as biocompatibility and design
          flexibility. These properties underpin novel medical devices designed
          to support tissue repair, before they biodegrade in situ into
          biocompatible by-products via established pathways.
          <br />
          <br />
          NovoSorb BTM (Biodegradable Temporizing Matrix) is the first product
          commercialized by PolyNovo. In 2015, following the FDA 510(K)
          clearance of BTM, PolyNovo was awarded an $11 million contract and a
          further $25 million in funding from the US-based Biomedical Advanced
          Research and Development Authority (BARDA) to complete a trial with
          BTM for full thickness burns.
          <br />
          <br />
          We have expanded our manufacturing facility and headquarters in
          Melbourne, Australia, establishing high quality processes, enabling
          product innovation and preparing for growth.
          <div
            className='go-back-button-booths-background'
            onClick={() => {
              setSponsorTen(false);
              document.documentElement.scrollTop = 500;
            }}
          >
            <span className='go-back-button-booths'>Go Back</span>
          </div>
        </div>
      </section>
    );
  }
};

export default Events;
