import '../styles/subscribe.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Subscribe = ({
  mobile,
  loginPage,
  setLoginPage,
  registerPage,
  setRegisterPage,
  subscribePage,
  setSubscribePage,
  setNotOptions,
  setPodcastLogo
}) => {
  useEffect(() => {
    setPodcastLogo(true);
  }, []);
  const isMobile = mobile ? '-mobile' : '';
  useEffect(() => {
    setSubscribePage(true);
    setNotOptions(false);
    if (loginPage) {
      setLoginPage(false);
    } else if (registerPage) {
      setRegisterPage(false);
    }
  }, []);

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const titleStyle = mobile ? 'mobile-title' : 'events-title';

  return (
    <section className='subscribe-container'>
      <h1 className={titleStyle}>SUBMISSIONS</h1>
      <div className='subscribe-box'>
        <p className='lorem-ipsum'>
          <br />
          To inquire about publishing your work with us, please contact us at:
          <br />
          <br />
          submissions@woundmasterclass.com
          <br />
          <br />
        </p>
      </div>
    </section>
  );
};

export default Subscribe;
