import '../styles/about.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import edboard from '../images/editorial board.webp';

const About = ({ setNotOptions, mobile, setPodcastLogo }) => {
  useEffect(() => {
    setNotOptions(true);
  }, []);

  useEffect(() => {
    setPodcastLogo(true);
  }, []);

  const aboutStyle = mobile ? 'mobile-about' : 'desktop-about';

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
  };

  return (
    <section className='about-container'>
      <div className='about-box-container' id='top-about-box'>
        <h1 className='about-title'>ABOUT</h1>
        <div className='about-box'>
          <p className='lorem-ipsum' id='about-p'>
            <br />
            Wound Masterclass is an international wound care journal. 
            <br />
            <br />
            We offer the reader opportunities to master specialised concepts in
            wound care that will enhance their clinical practice.
            <br />
            <br /> Founded and run by international experts in wound care, we
            will also be offering free interactive wound care training.
            <br />
            <br />
            <br />
            Please{' '}
            <Link to='/Register'>
              <span className='email-links' onClick={() => scrollToTop()}>
                register{' '}
              </span>
            </Link>
            with us to get full access, and to be notified about new and upcoming issues, articles,
            events and learning opportunities.
          </p>
        </div>
      </div>
      <div className='about-box-container'>
     
        <div className='about-box' id='ed-box'>
          <img src={edboard} className='ed-board-image'></img>
        </div>
      </div>
      {/* <div className='page-graphic-container' id={aboutStyle}>
        <h2 className='mobile-content-titles'>
          <span>UPCOMING CONTENT</span>
        </h2>
        <div className='page-graphic-box'>
          <img src={graphic} className='page-graphic'></img>
        </div>
      </div> */}
    </section>
  );
};

export default About;
